<!--
 * @Author: zoujp
 * @Date: 2020-11-26 15:15:23
 * @LastEditTime: 2024-03-14 09:59:19
 * @LastEditors: Please set LastEditors
 * @Description:
 * @FilePath: \user\src\views\themeSet\components\alertExport.vue
-->
<template>
  <en-dialog
    title="导出"
    :visible.sync="visible"
    width="700px"
    :close-on-click-modal="false"
    @close="closeFn"
  >
    <div class="export-container">
      <div class="item">
        <p class="title">数据范围</p>
        <el-radio-group v-model="mdType">
          <el-radio :label="1">筛选数据</el-radio>
          <el-radio :label="2">全部数据（近一年的数据）</el-radio>
        </el-radio-group>
      </div>
      <div class="item">
        <p class="title">字段范围</p>
        <div style="display: flex;">
          <el-radio-group v-model="isType">
            <el-radio :label="1">显示字段</el-radio>
            <el-radio :label="2">全部字段</el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <en-button type="primary" @click="confirmExport" :loading="btnLoading">确 定</en-button>
    </span>
  </en-dialog>
</template>
<script>
import { themeCommonService } from "@/api";
import Utils from "@/views/businessModel/list/data/utils";
import { base64Encode } from "./model/util/index.js";

export default {
  name: "alertCompare",
  props: {
    // templateId//模板id
    // tabId //页签id
    // filterFields //搜索字段
    // sortField //排序字段
    // shareUserId //分享用户id
    // 显示字段 [{field:"",name:""}]
    // 列表视图: 列头字段
    // 日历视图: 从/ptCalendarConfig.ajax的tileFields取
    // 分组视图从/ptGroupConfig.ajax的tileFields取
    // showList //全部字段
    exportData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      visible: false,
      settingList: [],
      checkedFields: [],
      formatFields: [],
      settingVisible: false,
      btnLoading: false,
      mdType: 1,
      isType: 1
    };
  },
  mounted() {

  },
  watch: {
    isType() {
      this.checkedFields = [];
      this.formatFields = "";
    },
    /**
     * @description: 监听弹窗
     */
    visible() {
      // if (val) { }
    }
  },
  computed: {
  },
  methods: {
    /**
     * @description: 关闭弹窗回调
     */
    closeFn() {
      this.visiable = false;
    },
    async confirmExport() {
      // 检查导出是否超阈值
      const {
        templateId, shareUserId, filterFields, activeTab, showList, sortField
      } = this.exportData;
      const {
        mdType, isType
      } = this;
      const requestData = {
        mdType,
        isType,
        templateId,
        tabId: activeTab || "",
        shareUserId
        // showList
      };
      // 筛选数据
      if (mdType === 1) {
        requestData.filterFields = base64Encode(JSON.stringify(filterFields) || JSON.stringify([]));
        requestData.sortField = base64Encode(JSON.stringify(sortField) || JSON.stringify({}));
      }
      if (isType === 1) {
        requestData.colList = base64Encode(JSON.stringify(showList) || JSON.stringify([]));
      }
      this.btnLoading = true;
      try {
        const res = await themeCommonService.queryExportPtDataCount(requestData);
        requestData.isJump = res;
        // res => true 超过阈值
        if (!res) {
          const response = await themeCommonService.ptExportTemplateEmptyOrDataList({
            ...requestData,
            requestType: "stream"
          });
          if (response.headers["content-type"] === "application/json;charset=UTF-8") {
            this.$message.error("导出失败");
          } else {
            Utils.exportData(response);
          }
        } else {
          // 导出超过阈值去下载中心
          this.$emit("toDownloadCenter");
          await themeCommonService.ptExportTemplateEmptyOrDataList({
            ...requestData,
            isJump: true
          });
        }
        this.btnLoading = false;
      } catch (error) {
        this.btnLoading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
</style>
