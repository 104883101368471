<!--
 * @Author: Do not edit
 * @Date: 2020-09-28 14:47:47
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-12-17 11:59:31
 * @Description: Do not edit
 * @FilePath: \user\src\views\themeSet\components\TimeResource.vue
-->
<template>
  <div class="time-resource" :style="bindStyle" @click="openList">
    <el-popover placement="bottom"  trigger="hover">
      <div class="cardpopo">
        <div class="col-txts col-tits" style="position:relative;">

        </div>
        <div class="col-supernatant">
          <div class="title ">{{ item.name }}</div>
                  <!-- 新样式 -->
                  <div class="col-state item">
                    <span class="iconfont icon">&#xe6d6;</span>
                    <span class="time-date">{{ item.P6 }}</span>
                  </div>
                  <div class="col-state item">
                    <span class="iconfont icon">&#xe6d7;</span>
                    <span class="time-date">{{ item.refResource[0].name}}</span>
                  </div>
                  <div class="users item">
                    <span class="iconfont icon">&#xe6d8;</span>
                    <el-popover
                    placement="bottom"
                    class="poopover"
                    width="100%"
                    trigger="hover">
                    <div class="tag-list">
                      <div
                          v-for="it in item.m6516"
                          :key="it.id"
                          size="small"
                          type="info"
                          class="tag-item-box"
                        >
                        <div class="sculpture-style-two"  >
                          <span>{{ it.name[0] }}</span>
                        </div>
                        <div>{{ it.name }}</div>
                      </div>
                    </div>
                    <div slot="reference">

                      <div class="sculpture-list" v-if="item.m6516 && item.m6516.length > 3">
                        <div v-for="(item2, index) in item.m6516.slice(0,3)" :key="index" class="sculpture-style"  >
                          <span v-if="index < 3">{{[...item2.name][0] || ""}}</span>
                        </div>
                        <div v-if="item?.m6516?.length >= 3" class="sculpture-style-other">
                          +{{item.m6516.length - 3}}
                        </div>
                      </div>
                      <div class="en-result-tags" v-else>
                        <div v-for="(item2, index) in item.m6516" :key="index" class="en-select-tag"  >
                          <span class="en-select__tags-thum en-select__tags-thum-text">
                            {{
                            item2.name.substr(0, 1)
                            }}
                          </span>
                          <span class="el-select__tags-text">{{ item2.name }}</span>
                        </div>
                      </div>
                    </div>
                    </el-popover>
                  </div>
        </div>
      </div>
      <div class="left" slot="reference">
        <div class="txts-wrap" >
            <div class="dot" v-show="item.refType===23&&item.readFlag==='000'">
            <div class="in"></div>
            </div>
          <div style="padding-right:5px;" @click.stop>
            <el-checkbox v-model="item.isChecked" v-if="item.createId.length && item.createId[0].id === userInfo.userId" @change="finish(item)">
            </el-checkbox>
          </div>
          <div class="txts-event" v-bind:class="{ finish: item.isChecked }" v-html="item.titleTxts" @click="readMsg(item)"></div>
        </div>
      </div>
    </el-popover>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";

export default {
  name: "TimeResource",
  props: {
    // name: String,
    // subname: String,
    time: String,
    timeField: {
      type: String
    },
    item: {
      type: Object,
      default() {
        return {};
      }
    },
    list: {
      type: Array,
      default() {
        return [];
      }
    },
    type: {
      type: String,
      default: "blue"
    },
    shouldPositionAbs: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      top: 0,
      height: 24
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    periodList() {
      // 前后20分钟的时间在一个时间段
      const isInRangeTime = (pointTime, time, range = 20) => {
        const period = new Date(pointTime).getTime() - new Date(time).getTime();
        return period <= range * 1000 * 60 && period >= -range * 1000 * 60;
      };
      if (this.item.periodList) {
        return this.item.periodList;
      }
      //  dayjs(item[this.timeField]).format("yyyy-MM-dd HH") === dayjs(this.time).format("yyyy-MM-dd HH") ||
      const res = this.list.filter((item) => isInRangeTime(item[this.timeField], this.time) && !item.periodList);
      res.forEach((item) => {
        item.periodList = [...res];
      });
      return res;
    },
    index() {
      return this.periodList.findIndex((item) => item.id === this.item.id);
    },
    listLength() {
      return this.periodList.length;
    },
    /**
      * @description: 添加样式
    */
    bindStyle() {
      const height = `${parseFloat(this.height)}px`;
      const width = `${100 / this.listLength}%`;
      const timeHour = dayjs(this.time).hour();
      const timeMinute = dayjs(this.time).minute();
      const tt = timeHour * 46 + timeMinute * 0.7;
      const top = `${parseFloat(tt)}px`;
      const left = `${(100 / this.listLength) * this.index}%`;
      let borderLeftColor = "";
      let backgroundColor = "";
      let borderColor = "";
      switch (this.type) {
        case "blue":
          borderColor = "rgba(70, 148, 223, 0.2)";
          borderLeftColor = "#3e90fe";
          backgroundColor = "rgba(236, 244, 252, 1)";
          break;
        case "red":
          borderColor = "rgba(247, 107, 107, 0.2)";
          borderLeftColor = "#F76B6B";
          backgroundColor = "rgba(254, 240, 240, 1)";
          break;
        case "orange":
          borderColor = "rgba(255, 173, 44, 0.2)";
          borderLeftColor = "#FFAD2C";
          backgroundColor = "rgba(255, 247, 233, 1)";

          break;
        case "gray":
          borderColor = "rgba(99, 108, 120, 0.2)";
          borderLeftColor = "#636C78";
          backgroundColor = "rgba(239, 240, 241, 1)";
          break;

        default:
          break;
      }
      const styleObj = {
        border: `1px solid${borderColor}`,
        background: backgroundColor,
        color: borderLeftColor,
        "line-height": height,
        "border-left": `2px solid${borderLeftColor}`,
        "border-top": "1px solid #dae5eb",
        "border-bottom": "1px solid #dae5eb"
      };
      if (this.shouldPositionAbs) {
        return {
          ...styleObj,
          height,
          width,
          top,
          left
        };
      }
      return {
        ...styleObj,
        position: "static",
        "margin-bottom": "10px"
      };
    }
  },
  methods: {
    openList() {
      console.log(this.periodList);
      if (this.periodList.length > 2) {
        this.$emit("viewPeriodList", this.periodList);
      }
      this.$emit("viewDetail", this.item);
    },
    /**
     * @description: 私密
     * @param {*} item
     * @return {*}
     */
    secret(item) {
      this.$emit("secret", item);
    },
    /**
     * @description: 完成
     * @param {*} item
     * @return {*}
     */
    finish(item) {
      this.$emit("finish", item);
    },
    /**
     * @description: 删除
     * @param {*}
     * @return {*}
     */
    del(item) {
      this.$emit("del", item);
    },
    /**
     * @description: 读取
     * @param {*} item
     * @return {*}
     */
    readMsg(item) {
      this.$emit("readMsg", item);
    }
  }
};
</script>
<style lang="scss" scoped>
.time-resource {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0px;
  z-index: 2;
  // display: flex;
  // justify-content: space-between;
  .left {
    padding-left: 14px;
  }
  .txts-wrap{
    display:flex;
    justify-content: flex-start;
    align-items: center;
    .dot{
      width:10px;
      height: 10px;
      padding-top: 0px;
      padding-right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      .in{
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #ff0000;
      }
    }
  }
  .txts-event {
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /deep/ .txts {
      margin-right: 8px;
    }
  }
  .right {
    margin-right: 14px;
  }
}
.cardpopo {
  font-size: 12px;
  .en-icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
  .txts {
    display: inline-block;
    vertical-align: middle;
  }
  .col-supernatant {
    max-height: 198px;
    overflow-y: auto;
    /deep/ .txts {
      display: block;
      line-height: 24px;
    }
  }
  .col-txts {
    line-height: 24px;
    font-size: 12px;
    color: #aeaeae;
  }
  .col-tits {
    font-size: 14px;
    color: #111;
  }
  .col-handle {
    text-align: right;
    position:absolute;
    top: 0;
    right: 0;
    .en-icon {
      cursor: pointer;
      &.cur {
        color: #409eff;
      }
    }
  }
}
.finish{
  text-decoration: line-through;
  color: #aeaeae;
}
.item{
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .icon{
        margin-right: 5px;
        // width: 20px;
        // height: 20px;
      }
      .check {
          margin-right: 10px;
        .el-checkbox__inner {
        }
      }
      .txts {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 500;
        color: #1B1C1E;
      }
      .time-date{
        line-height: 20px;
      }
    }
    .desc{
      word-break: break-all;
      color: #636C78;
      .text{
        line-height: 18px;
      }
    }
    .en-result-tags {
        display: flex;
        justify-content: flex-start;
        align-items: center;

      .en-select-tag {
        margin-right: 10px;
        background-color: #f5f8fc;
        border: 1px solid #dce5ec;
        padding: 2px;
        border-radius: 4px;
        color: #636c78;
        max-width: 100%;
        overflow: hidden;
        line-height: 16px;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
        .en-select__tags-thum {
          width: 20px;
          height: 20px;
          line-height: 20px;
          border-radius: 50%;
          color: #ffffff;
          overflow: hidden;
          display: inline-block;
          text-align: center;
          vertical-align: middle;
          margin-right: 4px;
          position: relative;
          top: -1px;
          background-color: #3e90fe;
          font-size: 12px;
        }
      }
    }
    .tag-list{
  display: flex;
  .tag-item-box{
    padding: 2px 10px;
    background: #f4f4f5;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-right: 5px;
    font-size: 12px;
  }
}
.sculpture-list{
    margin-left:5px;
    // width: 24px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    .sculpture-style-other{
      margin-left:-5px;
      width: 24px;
      height: 24px;
      line-height: 20px;
      border: 2px solid #F2F4F7;
      text-align: center;
      background: #B4BED8;
      border-radius: 50%;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
    }
    .sculpture-style{
      margin-left:-5px;
      width: 24px;
      height: 24px;
      line-height: 20px;
      border: 2px solid #F2F4F7;
      text-align: center;
      background: #3E90FE;
      border-radius: 50%;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
  .sculpture-list-two{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .sculpture-style-two{
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #F2F4F7;
        text-align: center;
        background: #3E90FE;
        border-radius: 50%;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
  }
    .poopover{
      height: 20px;
    }
    .icon {
        color: #a9b5c6;
      }
      .progress-style{
        width: 62px;
        height: 26px;
        background: #FFA32B;
        border-radius: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 14px;
      }
      .en-result-tags {
        display: flex;
        justify-content: flex-start;
        align-items: center;

      .en-select-tag {
        margin-right: 10px;
        background-color: #f5f8fc;
        border: 1px solid #dce5ec;
        padding: 2px;
        border-radius: 4px;
        color: #636c78;
        max-width: 100%;
        overflow: hidden;
        line-height: 16px;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
        .en-select__tags-thum {
          width: 20px;
          height: 20px;
          line-height: 20px;
          border-radius: 50%;
          color: #ffffff;
          overflow: hidden;
          display: inline-block;
          text-align: center;
          vertical-align: middle;
          margin-right: 4px;
          position: relative;
          top: -1px;
          background-color: #3e90fe;
          font-size: 12px;
        }
      }
    }
</style>
