<!--
 * @Author: zhulin
 * @Date: 2020-08-22 16:33:02
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-11-28 09:27:24
 * @Description: 主题-共享人通用弹窗
 * @FilePath: \user\src\views\themeSet\components\alertShare.vue
-->
<template>
  <en-dialog title="选择共享人" :visible.sync="show" width="900px" @close="closeDialog">
    <en-tabs :list="tabList" type="white" v-model="activeTab" @change="changeTabs"> </en-tabs>
    <div class="user-list">
      <div class="mods" v-for="item in personList" :key="item.id" @click="addFrequent(item)">
        <en-user-logo :user-name="item.name" size="32px"></en-user-logo>
        <span class="names">{{ item.name }}</span>
        <en-icon name="iconshanchu-liebiao" size="small" class="del-icon" v-if="activeTab === '1'" @click.native.stop="deleteFrequent(item)"></en-icon>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <en-button type="primary" @click="submitForm('nform')">确 定</en-button>
    </span>
  </en-dialog>
</template>
<script>
import { themeCommonService } from "@/api";

export default {
  name: "alertShare",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    shareUsers: {
      type: Array,
      default: () => []
    },
    templateId: {
      type: String
    },
    templateName: {
      type: String
    }
  },
  data() {
    return {
      data: [],
      activeTab: "1",
      frequentUser: [],
      tabList: [
        {
          id: "1",
          name: "常用"
        },
        {
          id: "2",
          name: "更多"
        }
      ]
    };
  },
  watch: {
    /**
     * @description: 监听弹窗
     */
    show(val) {
      if (val) {
        this.data = this.shareUsers || [];
        this.queryFrequent();
        console.log("弹窗", this.data);
      }
    }
  },
  computed: {
    personList() {
      return this.activeTab === "1" ? this.frequentUser : this.data;
    }
  },
  methods: {
    /**
     * @description: 查询分享用户
     */
    async queryFrequent() {
      const pa = { refType: 1, funcType: 1, uniqueId: this.templateId };
      const rsp = await themeCommonService.queryFrequent(pa);
      const arr = rsp || [];
      this.frequentUser = arr;
      console.log("this.frequentUser", this.frequentUser);
    },
    /**
     * @description: 关闭弹窗回调
     */
    closeDialog() {
      this.$emit("toShow", false);
    },
    /**
     * @description: 切换页签
     * @param {*}
     * @return {*}
     */
    changeTabs() {
      console.log(this.activeTab);
    },
    /**
     * @description: 删除常用人
     */
    async deleteFrequent(item) {
      const pa = {
        refId: item.id,
        refType: 1,
        funcType: 1,
        uniqueId: this.templateId
      };
      await themeCommonService.deleteFrequent(pa);
      this.$message("删除成功");
      this.queryFrequent();
    },
    /**
     * @description: 添加常用人
     */
    async addFrequent(item) {
      if (this.activeTab === "2") {
        const pa = {
          refId: item.id,
          refType: 1,
          funcType: 1,
          uniqueId: this.templateId
        };
        await themeCommonService.addFrequent(pa);
      }
      const data = {
        name: item.name,
        id: item.id
      };
      this.$emit("refreshData", data);
      this.closeDialog();
    }
  }
};
</script>
<style lang="scss" scoped>
.btn-list {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  .mods {
    margin-right: 8px;
    cursor: pointer;
  }
}
.add-btn {
  display: inline-block;
  vertical-align: middle;
}
.en-tabs {
  padding: 0;
}
.en-tabs /deep/ .en-tabs-item {
  width: 106px;
}
.en-tabs /deep/.active_bar {
  width: 120px !important;
}
.user-list {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #eaebef;
  padding: 10px 0;
  border-radius: 5px;
  border-top: 0;
  min-height: 400px;
  align-content: flex-start;
  .mods {
    width: 50%;
    cursor: pointer;
    padding: 0 20px;
    border-radius: 5px;
    .names {
      margin-left: 10px;
      font-size: 12px;
    }
    .del-icon {
      float: right;
      margin-top: 16px;
    }
    &:hover {
      background: #f5f8fd;
    }
  }
}
</style>
