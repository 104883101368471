<template>
  <div class="en-date-picker">
    <div class="en-date-picker-week">
      <div v-for="(item, k) in weekArr" :key="k" class="en-date-picker-week-item">
        {{ item }}
      </div>
    </div>
    <div class="en-date-picker-body">
      <div
        v-for="(item, index) in list"
        :key="item.timeStamp + index"
        :class="{
          'other-month': item.isOtherMonth,
          'current-date': isToday(item),
        }"
        class="en-date-picker-item"
      >
        <div class="label" @click="dayClick(item.date)">
          {{ item.date }}
        </div>

        <div class="col" v-if="item?.bData && item.bData.length > 0">
          <div
            v-for="(itemSun, k) in item.bData.filter((e, i) => i < 4)"
            :key="k"
            class="col-names"
            :class="getClass(itemSun,k)"
            @click.stop="cliclItem($event, itemSun, k, item.bData.length)"
          >
            <div v-if="itemSun.task===false && k < 3"></div>
            <div v-else>
              <el-popover
                ref="popver"
                :popper-options="{ removeOnDestroy: true, offset: [1000, 1000] }"
                @show="isShow(k)"
                popper-class="hoverPopover"
                placement="top"
                width="360"
                trigger="hover"
                v-if="item.bData.length < 4 || (item.bData.length > 3 && k < 3)"
              >
              <div class="cardpopo">
                <div class="titleIocon">
                  <i class="iconfont iconshujuzhanshi-liebiao"></i>
                </div>

                <div class="col-txts col-tits" style="position: relative">
                  <div
                    class="col-handle-check"
                    @click="finish({ ...itemSun, isChecked: !itemSun.isChecked })"
                  >
                    <i class="el-icon-check"></i>
                  </div>
                  <div
                    class="col-handle"
                    v-if="
                      itemSun.createId?.length &&
                      itemSun.createId[0].id === userInfo.userId &&
                      itemSun.addType.toString() === '2'
                    "
                  >
                    <span title="删除"
                      ><en-icon
                        name="shanchu-kuaijiecaozuo"
                        size="small"
                        @click.native="del(itemSun)"
                        >删除</en-icon
                      ></span
                    >
                    <span :title="itemSun.secretType.toString() === '1' ? '取消私密' : '私密'">
                      <en-icon
                        name="simi"
                        size="small"
                        :color="itemSun.secretType.toString() === '2' ? '#A9B5C6' : ''"
                        @click.native="secret(itemSun)"
                        >{{ itemSun.secretType.toString() === "1" ? "取消私密" : "私密" }}</en-icon
                      >
                    </span>
                  </div>
                  <div
                    class="col-handle"
                    v-else-if="
                      itemSun.createId?.length &&
                      itemSun.createId[0].id === userInfo.userId &&
                      itemSun.addType.toString() !== '2'
                    "
                  >
                    <span :title="itemSun.secretType.toString() === '1' ? '取消私密' : '私密'">
                      <en-icon
                        name="simi"
                        size="small"
                        :color="itemSun.secretType.toString() === '2' ? '#A9B5C6' : ''"
                        @click.native="secret(itemSun)"
                        >{{ itemSun.secretType.toString() === "1" ? "取消私密" : "私密" }}</en-icon
                      >
                    </span>
                  </div>
                  <div class="col-handle" v-else></div>

                </div>
                <div class="col-supernatant">

                  <div class="title ">{{ itemSun.name }}</div>
                  <!-- 新样式 -->
                  <div class="col-state item">
                    <span class="iconfont icon">&#xe6d6;</span>
                    <span class="time-date">{{ itemSun.P6 }}</span>
                  </div>
                  <div class="col-state item">
                    <span class="iconfont icon">&#xe6d7;</span>
                    <span class="time-date">{{ itemSun.refResource[0].name}}</span>
                  </div>
                  <div class="users item">
                    <span class="iconfont icon">&#xe6d8;</span>
                    <el-popover
                    placement="bottom"
                    class="poopover"
                    width="100%"
                    trigger="hover">

                    <div class="tag-list">

                      <div
                          v-for="it in itemSun.m6516"
                          :key="it.id"
                          size="small"
                          type="info"
                          class="tag-item-box"
                        >
                        <div class="sculpture-style-two"  >
                          <span>{{ it.name[0] }}</span>
                        </div>
                        <div>{{ it.name }}</div>
                      </div>
                    </div>
                    <div slot="reference">

                      <div class="sculpture-list" v-if="itemSun.m6516 && itemSun.m6516.length > 3">
                        <div v-for="(itemSun2, index) in itemSun.m6516.slice(0,3)" :key="index" class="sculpture-style"  >
                          <span v-if="index < 3">{{[...itemSun2.name][0] || ""}}</span>
                        </div>
                        <div v-if="itemSun?.m6516?.length >= 3" class="sculpture-style-other">
                          +{{itemSun.m6516.length - 3}}
                        </div>
                      </div>
                      <div class="en-result-tags" v-else>
                        <div v-for="(itemSun2, index) in itemSun.m6516" :key="index" class="en-select-tag"  >
                          <span class="en-select__tags-thum en-select__tags-thum-text">
                            {{
                            itemSun2.name.substr(0, 1)
                            }}
                          </span>
                          <span class="el-select__tags-text">{{ itemSun2.name }}</span>
                        </div>
                      </div>
                    </div>
                    </el-popover>
                  </div>

                  <div class="desc item">
                    <span class="iconfont icon">&#xe868;</span>
                    <span class="text">{{ itemSun.description }}</span>
                  </div>
                </div>
              </div>
              <div class="" slot="reference">
                <div class="txts-wrap">
                  <div class="dot" v-show="itemSun.refType === 23 && itemSun.readFlag === '000'">
                    <div class="in"></div>
                  </div>
                  <div style="padding-right: 5px" @click.stop>
                    <el-checkbox
                      v-model="itemSun.isChecked"
                      @click.stop
                      v-if="itemSun.createId?.length && itemSun.createId[0].id === userInfo.userId"
                      @change="finish(itemSun)"
                    >
                    </el-checkbox>
                  </div>
                  <div
                    class="txts-event dis-sb"
                    v-bind:class="{ finish: itemSun.isChecked }"
                    @click="readMsg(itemSun)"
                  ><span>{{ itemSun.refResource[0].name }}</span>
                  <span>
                    <span v-for="i in itemSun.P2" :key="i">{{ i.name }}</span>
                    <span>{{ itemSun.m5300 }}</span>
                  </span>

                </div>
                </div>
              </div>
            </el-popover>

            <el-popover
              v-if="item.bData?.length > 3 && k === 3"
              :ref="itemSun.id"
              popper-class="morePopover"
              placement="bottom"
              trigger="click"
            >
              <div class="content">
                <div class="title">
                  <div class="time">{{ itemSun.modifyTime }}</div>
                  <div>
                    <i
                      class="el-icon-close el-input__icon"
                      slot="suffix"
                      style="cursor: pointer"
                      @click="closeModel(itemSun.id)"
                    >
                    </i>
                  </div>
                </div>
                <div
                  class="txts-wrap"
                  v-for="(itemSunMore, k) in item.bData.filter((m, i) => i > 2)"
                  :key="k"
                  @click.stop="cliclItem($event, itemSunMore, k, item.bData?.length)"
                >

                  <div style="padding-right: 5px" @click.stop>
                    <el-checkbox
                      v-model="itemSunMore.isChecked"
                      @click.stop
                      v-if="
                        itemSunMore.createId?.length &&
                        itemSunMore.createId[0].id === userInfo.userId
                      "
                      @change="finish(itemSunMore)"
                    >
                    </el-checkbox>
                  </div>
                  <div
                    class="txts-event"
                    v-bind:class="{ finish: itemSunMore.isChecked }"
                    v-html="itemSunMore.titleTxts"
                    @click="readMsg(itemSunMore)"
                  >
                </div>
                </div>
              </div>
              <div slot="reference">+{{ item.bData?.length - 3 }} 更多</div>
            </el-popover>
            </div>
          </div>
        </div>
      </div>
    </div>
    <business-dialog
      v-if="businessDialogVisible"
      :business-params="businessParams"
      :dialog-visible.sync="businessDialogVisible"
    ></business-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import enfryComponents from "enfry-component";
import { date } from "@/tools/date";
import BusinessDialog from "@/components/businessDetail/businessDialog.vue";
import { viewBusinessDialogParams } from "@/components/businessDetail/helper";
import { defaultListService } from "@/api";
import { enService } from "@/api/en/index";

const { datePickerInit } = enfryComponents.$tools;
export default {
  name: "viewsCalender",
  components: {
    BusinessDialog
  },
  props: {
    templateId: {
      type: String
    },
    changeDate: {
      type: [String, Date]
    },
    startD: {
      type: String
    },
    endD: {
      type: String
    },
    data: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {

      otherMonth: true,
      businessDialogVisible: false,
      startDay: 0,
      list: [],
      // 当前年
      currentYear: this.getDateYMD(this.changeDate, "y"),
      // 当前月
      currentMonth: this.getDateYMD(this.changeDate, "m"),
      // 当前天
      currentDate: this.getDateYMD(this.changeDate, "d"),
      left: 0,
      top: 0,
      userName: this.$store.getters.userInfo.name,
      newData: {}
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    // 此处由于日历面板的第一天位置需要动态变幻
    weekArr() {
      // if (parseInt(this.startDay, 10) === 1) {
      // return ["一", "二", "三", "四", "五", "六", "日"];
      // }
      return ["日", "一", "二", "三", "四", "五", "六"];
    }
  },
  watch: {
    changeDate(val) {
      console.log("123321");
      this.currentYear = this.getDateYMD(val, "y");
      this.currentMonth = this.getDateYMD(val, "m");
      this.currentDate = this.getDateYMD(val, "d");
      this.getDateInfo();
    },
    businessDialogVisible(newVal) {
      if (!newVal) {
        this.$emit("reload");
      }
    },
    data(value) {
      console.log("123123");
      this.newData = JSON.parse(JSON.stringify(this.data));
      this.getDateInfo();

      this.getDateNewList();
    }
  },

  mounted() {
    this.getDateInfo();
  },
  methods: {

    isShow(index) {
      // this.$refs[`popver${index}`].styletop
      // this.$refs.popver[index].popperOptions = {
      // offset: [100, 0]
      // };
    },
    closeModel(id) {
      this.$refs[id][0].doClose();
    },
    getDate(item, data) {
      const dataItem = data[
        `${this.currentYear}-${
          Number(!item.isOtherMonth ? this.currentMonth : this.currentMonth - 1) < 10
            ? `0${!item.isOtherMonth ? this.currentMonth : this.currentMonth - 1}`
            : !item.isOtherMonth
            ? this.currentMonth
            : this.currentMonth - 1
        }-${Number(item.date) < 10 ? `0${item.date}` : item.date}`
      ];
      return dataItem;
    },
    getDateNewList() {
     // 遍历每个日期的任务
     // 遍历任务数据
      const a = this.list;
      const b = this.newData;
      let newB = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const key in b) {
        // eslint-disable-next-line no-prototype-builtins
        if (b.hasOwnProperty(key)) {
          newB = [...newB, ...b[key]];
        }
      }
          // eslint-disable-next-line no-restricted-syntax
          for (let index = 0; index < newB.length; index++) {
            const element = JSON.parse(JSON.stringify(newB[index]));
            if (element?.P6) {
              const startDate = new Date(`${element?.P6?.split(",")[0].substring(0, 10)} 00:00:00`);
              const endDate = new Date(`${element?.P6?.split(",")[1].substring(0, 10)} 23:59:59`);
            // 遍历数组a
            for (let i = 0; i < a.length; i++) {
              const currentDate = new Date(a[i].time); // a[i].time 2023-11-21 00:00:00
              if (startDate <= currentDate && currentDate <= endDate) {
                if ((currentDate.getDay() === 0 && endDate.getTime() - startDate.getTime() > 0) || currentDate.getTime() === startDate.getTime()) {
                  const remainingDays = this.calculateRemainingDays(currentDate, endDate);
                  let width = remainingDays || 1; // 初始化为剩余天数
                  // 如果剩余天数加上当前日期的星期几超过了周六，就取周六减去当前星期几
                  if (currentDate.getDay() + remainingDays > 6) {
                    width = 7 - currentDate.getDay();
                  }
                  // itemNum计算出的是在区间内不同任务的个数
                  const itemNum = this.checkBDataInTimeRange(element, this.list, startDate, endDate);
                  // itemLength表示添加正常任务的时候这个天数下面的任务数
                  const itemLength = a[i].bData.length;
                  if (itemLength > 3) {
                    width = 1;
                  }

                  if (itemNum === 0) {
                    a[i].bData.push({ ...element, width });
                  } else if (itemNum === 1) {
                    if (itemLength < 3) {
                      a[i].bData.push({ ...element, width });
                    } else {
                      a[i].bData.push(...[{ task: false, width: 1, id: element.id }, { ...element, width }]);
                    }
                  } else if (itemNum === 2) {
                    if (itemLength === 0 || itemLength === 1) {
                      a[i].bData.push(...[{ task: false, width: 1, id: element.id }, { task: false, width: 1, id: element.id }, { ...element, width: 1 }]);
                    }
                    if (itemLength === 2) {
                      a[i].bData.push(...[{ ...element, width }]);
                    }
                    if (itemLength === 3) {
                      a[i].bData.push(...[{ ...element, width: 1 }]);
                    }
                  } else if (itemNum === 3) {
                    if (itemLength === 0) {
                      a[i].bData.push(...[{ task: false, width: 1, id: element.id }, { task: false, width: 1, id: element.id }, { task: false, width: 1, id: element.id }, { ...element, width: 1 }]);
                    }
                    if (itemLength === 1) {
                      a[i].bData.push(...[{ task: false, width: 1, id: element.id }, { task: false, width: 1, id: element.id }, { ...element, width: 1 }]);
                    }
                    if (itemLength === 2) {
                      a[i].bData.push(...[{ task: false, width: 1, id: element.id }, { ...element, width: 1 }]);
                    }
                    if (itemLength > 2) {
                      a[i].bData.push(...[{ ...element, width: 1 }]);
                    }
                  } else if (itemNum > 3) {
                    a[i].bData.push(...[{ ...element, width: 1 }]);
                  }
                } else if (a[i].bData.length < 3) {
                        a[i].bData.push({ task: false, id: element.id });
                      }
              }
            }
            }
          }

    // 输出结果
    console.log(this.list, "339");
    },
    checkBDataInTimeRange(element, dataArray, startTime, endTime) {
      const startTimestamp = new Date(startTime).getTime();
      const endTimestamp = new Date(endTime).getTime();
      // 过滤数组
      const filteredArray = dataArray.filter((item) => {
          const itemTime = new Date(item.time).getTime(); // 将数组中的时间字符串转换为时间戳
          return itemTime >= startTimestamp && itemTime <= endTimestamp; // 比较时间戳是否在给定范围内
      });
      let index = 0; // 初始化索引为0
      const checkedIds = {}; // 用于存放已经检查过的id
      for (let i = 0; i < filteredArray.length; i++) {
          const subArr = filteredArray[i].bData;
          for (let j = 0; j < subArr.length; j++) {
              const item = subArr[j];
              if (item.id === element.id) {
                  if (!checkedIds[element.id]) {
                      checkedIds[element.id] = true; // 将已经检查过的id标记为true
                  }
              } else if (!checkedIds[item.id] && i !== 0) {
                          index++; // 如果id不同且之前未检查过，则索引加1
                          checkedIds[item.id] = true; // 将已经检查过的id标记为true
                  }
          }
      }

      return index;
    },
    // 计算剩余任务天数的函数
    calculateRemainingDays(currentDate, endDate) {
      const oneDay = 24 * 60 * 60 * 1000; // 一天的毫秒数
      const remainingDays = Math.round((endDate.getTime() - currentDate.getTime()) / oneDay);
      return remainingDays;
    },
    isToday(item) {
      return (
        dayjs(Date.now()).format("YYYY-MM-DD")
        === dayjs(new Date(`${item.year}-${item.month}-${item.date}`)).format("YYYY-MM-DD")
      );
    },
    /**
     * @description: 私密
     * @param {*} item
     * @return {*}
     */
    secret(item) {
      const params = {
        templateId: this.templateId,
        id: item.id, // 业务建模数据ID
        refType: item.refType,
        secretType: item.secretType.toString() === "1" ? 2 : 1 // Number1-私密、2-公开
      };
      this.$confirm(
        `确定要${item.secretType.toString() === "1" ? "取消私密" : "设为私密"}吗？`,
        "",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      ).then(() => {
        this.secretSubmit(item, params);
      });
    },
    /**
     * @description: 私密提交
     * @param {*}
     * @return {*}
     */
    async secretSubmit(item, params) {
      await defaultListService.ptSecret(params);
      this.$message("操作成功");
      this.$set(item, "secretType", item.secretType.toString() === "1" ? 2 : 1);
      this.$forceUpdate();
    },
    /**
     * @description: 删除
     * @param {*} item
     * @return {*}
     */
    del(item) {
      const params = {
        templateId: this.templateId, // 主题模板ID
        id: item.id // 业务建模数据ID
      };
      this.$confirm("确定要删除吗？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.delSubmit(params);
      });
    },
    /**
     * @description: 删除提交
     * @param {*}
     * @return {*}
     */
    async delSubmit(params) {
      await defaultListService.ptOut(params);
      this.$message("操作成功");
      // eslint-disable-next-line
      for (const key in this.data) {
        this.data[key].forEach((a, b) => {
          if (params.id === a.id) {
            this.data[key].splice(b, 1);
          }
        });
      }
      this.$forceUpdate();
    },
    /**
     * @description: 读取消息
     * @param {*}
     * @return {*}
     */
    async readMsg(item) {
      if (item.refType === 23 && item.readFlag === "000") {
        // 消息中心
        const params = {
          ids: item.id,
          isBatch: 1
        };
        await enService.readNotice(params);
        this.$set(item, "readFlag", "001");
        this.$forceUpdate();
      }
    },
    /**
     * @description: 完成操作
     * @param {*}
     * @return {*}
     */
    finish(item) {
      console.log(item.isChecked, "1");
      let msg = "确定要标记为$$完成吗?";
      if (!item.isChecked) {
        msg = msg.replace("$$", "未");
      } else {
        msg = msg.replace("$$", "");
      }
      const params = {
        templateId: this.templateId,
        id: item.id,
        refType: item.refType,
        finishType: item.isChecked ? 1 : 2
      };
      this.$confirm(msg, "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.finishSubmit(item, params);
        })
        .catch(() => {
          this.$set(item, "finishType", item.finishType);
          this.$set(item, "isChecked", !item.isChecked);
          this.$forceUpdate();
        });
    },
    /**
     * @description: 完成操作提交
     * @param {*}
     * @return {*}
     */
    async finishSubmit(item, params) {
      await defaultListService.ptFinish(params);
      this.$emit("refreshList");
      this.$message("操作成功！");
      this.$set(item, "finishType", params.finishType);
      this.$set(item, "isChecked", item.isChecked);
      this.$forceUpdate();
    },
    getDateYMD(date, key) {
      const obj = {
        y: dayjs(date).year(),
        m: dayjs(this.changeDate).month() + 1,
        d: dayjs(this.changeDate).date()
      };
      return obj[key];
    },
    /**
     * @description 获取时间
     */
    getDateInfo() {
      this.getListData();
    },
    /**
     * @description 获取列表数据
     */
    getListData() {
      if (this.otherMonth) {
        this.list = datePickerInit.getWholeMonthData(this.currentYear, this.currentMonth, 0);
      } else {
        this.list = datePickerInit.getMonthData(this.currentYear, this.currentMonth, 0);
      }
      const daysOfWeek = ["日", "一", "二", "三", "四", "五", "六"];
      let dayIndex = 0;

      for (let i = 0; i < this.list.length; i++) {
        this.list[i].day = daysOfWeek[dayIndex];
        dayIndex = (dayIndex + 1) % 7; // 循环获取星期几的索引
        // 把年月日拼起来 好筛选添加数据
        this.list[i].time = `${this.list[i].year}-${this.list[i].month}-${this.list[i].date} 00:00:00`;
        this.list[i].bData = [];
      }
    },
    /**
     * @description 判断 显示的class 自己申请 他人申请 已结束
     */
    getClass(item, k) {
      let str = "";
      if (item.finishType === 1) {
        str = "end";
      }
      // 传入的时间字符串
      const inputTime = item.modifyTime;

      // 将传入的时间字符串转换为 Date 对象
      const inputDate = new Date(inputTime);

      // 获取当前时间的 Date 对象
      const currentDate = new Date();

      // 将两个时间都转换为当天零点的时间戳
      const inputTimestamp = new Date(
        inputDate.getFullYear(),
        inputDate.getMonth(),
        inputDate.getDate()
      ).getTime();
      const currentTimestamp = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      ).getTime();

      // 比较时间戳
      if (item.finishType !== 1 && inputTimestamp < currentTimestamp) {
        str = "red";
      } else if (item.finishType !== 1 && inputTimestamp > currentTimestamp) {
        str = "mine";
      } else if (item.finishType !== 1 && inputTimestamp === currentTimestamp) {
        str = "org";
      } else if (k === 3) {
        str = "red";
      }
      let widthStr = "";
      widthStr = `width${item.width}`;
      let indexStr = "";
      if (item.width >= 1) {
        indexStr = "index9999";
      } else {
        indexStr = "index1";
      }
      return `${str} ${widthStr} ${indexStr}`;
      // 新版逻辑判断修改 根据时间判断颜色
      // let str = "";
      // if (item.billStatus === "003") {
      //   // 已结束
      //   str = "end";
      // } else if (this.userName === item.createName) {
      //   str = "mine";
      // } else {
      //   str = "he";
      // }
      // return str;
    },

    /**
     * @description 点击的 条目 弹出显示
     */
    cliclItem(e, item, key, length) {
      console.log(key, length);
      if (length < 4 || (length > 3 && key < 3)) {
        const tag = e.currentTarget.getBoundingClientRect();
        this.top = tag.top + 26;
        this.left = tag.left;
        if (window.document.documentElement.clientHeight - this.top < 55) {
          this.top -= 55;
        }
        if (item.businessKey && [3, 22].includes(item.refType)) {
          this.businessParams = viewBusinessDialogParams({
            templateId: item.templateId,
            id: item.businessKey,
            businessType: 2
          });
          this.businessDialogVisible = true;
        }
        console.log(item);
      } else {
        console.log(item);
      }
    },
    /**
     * @description 判断 2个 对象是否相等
     */
    isequals(x, y) {
      const in1 = x instanceof Object;
      const in2 = y instanceof Object;
      if (!in1 || !in2) {
        return x === y;
      }
      if (Object.keys(x).length !== Object.keys(y).length) {
        return false;
      }
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const p in x) {
        const a = x[p] instanceof Object;
        const b = y[p] instanceof Object;
        if (a && b) {
          return this.equals(x[p], y[p]);
        }
        if (x[p] !== y[p]) {
          return false;
        }
      }
      return true;
    },
    dayClick(date) {
      this.$parent.activeView = "day";
      const nowD = date.toString();
      date = nowD.length < 2 ? `0${date}` : date;
      this.$parent.changeDate = `${this.currentYear}-${this.currentMonth}-${date}`;
      console.log(`${this.currentYear}-${this.currentMonth}-${date}`);
      this.$parent.changeViews();
    }
  }
};
</script>

<style lang="scss" scoped>
.el-popover__title {
  font-size: 12px;
}

.hoverPopover {
  top: -10px;

  .cardpopo {
    min-height: 160px;
    min-width: 200px;
    font-size: 12px;
    position: relative;
    margin-bottom: 10px;
    padding: 10px;

    .titleIocon {
      position: absolute;
      top: -42px;
      left: calc(50% - 30px);
      width: 60px;
      height: 60px;
      line-height: 60px;
      background: #a871f6;
      box-shadow: 0px 3px 7px 0px rgba(151, 138, 242, 0.35);
      border-radius: 50%;
      text-align: center;
      color: #fff;

      .iconfont {
        font-size: 28px;
      }
    }

    .en-icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }

    .txts {
      display: inline-block;
      vertical-align: middle;
    }

    .col-supernatant {
      padding-top: 30px;
      max-height: 198px;
      overflow-y: auto;

      /deep/ .txts {
        display: block;
        line-height: 24px;
      }
    }

    .col-txts {
      line-height: 24px;
      font-size: 12px;
      color: #aeaeae;
    }

    .col-tits {
      font-size: 14px;
      color: #111;
    }

    .col-handle {
      text-align: right;
      position: absolute;
      top: 0;
      right: 0;

      .en-icon {
        cursor: pointer;

        &.cur {
          color: #409eff;
        }
      }
    }

    .col-handle-check {
      position: absolute;
      top: 5px;
      right: 34px;
      background-color: #bbc5ce;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      line-height: 16px;
      text-align: center;

      .el-icon-check {
        font-size: 12px;
        cursor: pointer;
        color: #fff;
      }
    }
  }
}

.en-date-picker {
  position: relative;
  width: 100%;
  border: 1px solid #e8ecf2;
  border-top: 0;
  height: calc(100% - 50px);
  overflow: hidden;
  overflow-y: auto;

  .en-date-picker-week {
    display: flex;
    justify-content: flex-start;
    line-height: 48px;
    background-color: #f6fafd;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    color: #333333;
    font-size: 12px;
    position: relative;
    z-index: 2;

    .en-date-picker-week-item {
      width: calc(100% / 7);
      text-align: center;
      border-right: 1px solid #e8ecf2;

      &:nth-last-child(1) {
        border-right: none;
      }
    }
  }

  .en-date-picker-body {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    height: calc(100% - 95px);

    .en-date-picker-item {
      height: 130px;
      position: relative;
      width: calc(100% / 7);
      border-right: 1px solid #e8ecf2;
      border-top: 1px solid #e8ecf2;
      cursor: pointer;

      &:hover {
        background-color: #f5f9fd;
      }

      &:nth-child(7n) {
        border-right: none;
      }

      .label {
        position: absolute;
        right: 14px;
        top: 7px;
        width: 24px;
        height: 24px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        color: #333333;
        line-height: 24px;
        font-size: 12px;
        text-align: center;
      }

      &.current-date {
        .label {
          background-color: rgba(62, 144, 254, 1);
          color: #ffffff;
          border-radius: 50%;
        }
      }

      &.other-month {
        .label {
          color: #cdcdcd;
        }
      }

      // 内部信息
      .col {
        width: 100%;
        height: 90px;
        position: absolute;
        top: 37px;

        .col-names {
          z-index: 99;
          cursor: pointer;
          font-family: Microsoft YaHei;
          margin-bottom: 1px;
          height: 22px;
          font-size: 12px;
          line-height: 20px;
          padding: 0 2px;
          position: relative;

          .txts-wrap {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .dot {
              width: 10px;
              height: 10px;
              padding-top: 0px;
              padding-right: 5px;
              display: flex;
              justify-content: center;
              align-items: center;

              .in {
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background: #ff0000;
              }
            }
          }

          .txts-event {
            // float: left;
            word-break: break-all;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            /deep/ .txts {
              margin-right: 8px;
            }
          }

          // .txts-time {
          //   float: right;
          // }
        }
        .index9999 {
          z-index: 999;
        }
        .index1 {
          z-index: 1;
        }
        .col-names.he {
          border-left: 2px solid rgb(70, 148, 223);
          border-top: 1px solid rgb(218, 229, 235);
          border-bottom: 1px solid rgb(218, 229, 235);
          background: rgb(236, 244, 252);
          color: rgb(70, 148, 223);
        }

        .col-names.end {
          background: #eff0f1;
          border: 1px solid rgba(99, 108, 120, 0.2);
          border-left: 2px solid #636b77;
          color: rgba(99, 107, 119, 0.5);

          /deep/ .el-checkbox .el-checkbox__inner {
            border-color: rgba(169, 181, 198, 1);
          }

          /deep/ .el-checkbox .is-checked .el-checkbox__inner {
            background-color: rgba(169, 181, 198, 1);
            color: #fff;
          }
        }

        .col-names.end:hover {
          background-color: rgba(99, 108, 120, 0.25);
        }

        .col-names.org {
          background: #fff6e9;
          border: 1px solid rgba(255, 163, 44, 0.2);
          border-left: 2px solid rgba(255, 163, 44, 1);
          color: rgba(255, 163, 44, 1);

          /deep/ .el-checkbox .el-checkbox__inner {
            border-color: rgba(255, 173, 44, 1);
          }

          /deep/ .el-checkbox .is-checked .el-checkbox__inner {
            background-color: rgba(255, 173, 44, 1);
            color: #fff;
          }
        }

        .col-names.org:hover {
          background-color: rgba(255, 173, 44, 0.25);
        }

        .col-names.red {
          background: rgba(255, 239, 238, 1);
          border: 1px solid rgba(253, 99, 91, 0.2);
          border-left: 2px solid rgba(253, 99, 91, 1);
          color: #fd635b;

          /deep/ .el-checkbox .el-checkbox__inner {
            border-color: rgba(247, 107, 107, 1);
          }

          /deep/ .el-checkbox .is-checked .el-checkbox__inner {
            background-color: rgba(247, 107, 107, 1);
            color: #fff;
          }
        }

        .col-names.red:hover {
          background-color: rgba(253, 99, 91, 0.25);
        }

        .col-names.mine {
          background: rgba(236, 244, 252, 1);
          border: 1px solid rgba(70, 148, 223, 0.2);
          border-left: 2px solid rgba(70, 148, 223, 1);
          color: rgba(70, 148, 223, 1);

          /deep/ .el-checkbox .el-checkbox__inner {
            border-color: rgba(62, 144, 254, 1);
          }

          /deep/ .el-checkbox .is-checked .el-checkbox__inner {
            background-color: rgba(62, 144, 254, 1);
            color: #fff;
          }
        }

        .col-names.mine:hover {
          background: rgba(62, 144, 254, 0.25);
        }
      }
    }
  }
}

// .hidden{
//   visibility: hidden;
// }
// 点击 弹出
.cardinfo-alert {
  width: 240px;
  height: 60px;
  background: #fff;
  position: fixed;
  z-index: 999999;
  border: 1px solid rgba(232, 236, 242, 1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  border-radius: 2px;

  .keyset-card {
    padding-top: 15px;
    margin-bottom: 0;
    border-radius: 5px;
    padding: 10px;
    position: relative;
    background: #fff;
    zoom: 1;
    line-height: 24px;
    font-size: 12px;
    font-family: Microsoft YaHei, Arial, sans-serif;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);

    div {
      line-height: 24px;
      height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      width: 100%;
    }

    // .col-names {
    //   float: left;
    //   color: #606060;
    //   text-align: left;
    // }
    // .col-times {
    //   text-align: right;
    //   float: right;
    //   color: #aeaeae;
    //   text-align: right;
    // }
    // .col-local {
    //   text-align: left;
    //   float: left;
    //   color: #aeaeae;
    // }
    // .col-state {
    //   text-align: right;
    //   float: right;
    //   text-align: right;
    // }
  }
}

.finish {
  text-decoration: line-through;
  color: #aeaeae;
}

.morePopover {
  .content {
    width: 440px;
    margin-left: -12px;
    margin-right: -12px;

    .title {
      padding: 0 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .time {
        font-size: 14px;
        font-weight: 400;
        color: #1a1c1e;
      }

      .el-icon-close {
        font-size: 16px;
        color: #A9B5C6;
      }
    }

    .txts-wrap {
      padding: 0 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 36px;
      cursor: pointer;

      .txts-event {
        width: 100%;
        display: flex;
        justify-content: space-between;
        color:#1A1C1E;
      }
      .txts-event:hover {
        color:#3E90FE;
      }
    }

    .txts-wrap:hover {
      background: #f5f8fc;
    }
  }
}
.item{
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .icon{
        margin-right: 5px;
        // width: 20px;
        // height: 20px;
      }
      .check {
          margin-right: 10px;
        .el-checkbox__inner {
        }
      }
      .txts {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 500;
        color: #1B1C1E;
      }
      .time-date{
        line-height: 20px;
      }
    }
    .desc{
      word-break: break-all;
      color: #636C78;
      .text{
        line-height: 18px;
      }
    }
    .en-result-tags {
        display: flex;
        justify-content: flex-start;
        align-items: center;

      .en-select-tag {
        margin-right: 10px;
        background-color: #f5f8fc;
        border: 1px solid #dce5ec;
        padding: 2px;
        border-radius: 4px;
        color: #636c78;
        max-width: 100%;
        overflow: hidden;
        line-height: 16px;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
        .en-select__tags-thum {
          width: 20px;
          height: 20px;
          line-height: 20px;
          border-radius: 50%;
          color: #ffffff;
          overflow: hidden;
          display: inline-block;
          text-align: center;
          vertical-align: middle;
          margin-right: 4px;
          position: relative;
          top: -1px;
          background-color: #3e90fe;
          font-size: 12px;
        }
      }
    }
    .tag-list{
  display: flex;
  .tag-item-box{
    padding: 2px 10px;
    background: #f4f4f5;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-right: 5px;
    font-size: 12px;
  }
}
.sculpture-list{
    margin-left:5px;
    // width: 24px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    .sculpture-style-other{
      margin-left:-5px;
      width: 24px;
      height: 24px;
      line-height: 20px;
      border: 2px solid #F2F4F7;
      text-align: center;
      background: #B4BED8;
      border-radius: 50%;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
    }
    .sculpture-style{
      margin-left:-5px;
      width: 24px;
      height: 24px;
      line-height: 20px;
      border: 2px solid #F2F4F7;
      text-align: center;
      background: #3E90FE;
      border-radius: 50%;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
  .sculpture-list-two{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .sculpture-style-two{
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #F2F4F7;
        text-align: center;
        background: #3E90FE;
        border-radius: 50%;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
  }
    .poopover{
      height: 20px;
    }
    .icon {
        color: #a9b5c6;
      }
      .progress-style{
        width: 62px;
        height: 26px;
        background: #FFA32B;
        border-radius: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 14px;
      }
      .en-result-tags {
        display: flex;
        justify-content: flex-start;
        align-items: center;

      .en-select-tag {
        margin-right: 10px;
        background-color: #f5f8fc;
        border: 1px solid #dce5ec;
        padding: 2px;
        border-radius: 4px;
        color: #636c78;
        max-width: 100%;
        overflow: hidden;
        line-height: 16px;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
        .en-select__tags-thum {
          width: 20px;
          height: 20px;
          line-height: 20px;
          border-radius: 50%;
          color: #ffffff;
          overflow: hidden;
          display: inline-block;
          text-align: center;
          vertical-align: middle;
          margin-right: 4px;
          position: relative;
          top: -1px;
          background-color: #3e90fe;
          font-size: 12px;
        }
      }
    }
    .dis-sb{
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
</style>
<style>
.hoverPopover{
  top: 10px !important;
}
.width1 {
  width:100% !important;
}
.width2 {
  width:calc(200% + 1px)!important;
}
.width3 {
  width:calc(300% + 2px) !important;
}
.width4 {
  width:calc(400% + 3px) !important;
}
.width5 {
  width:calc(500% + 4px)  !important;
}
.width6 {
  width:calc(600% + 5px) !important;
}
.width7 {
  width:calc(700% + 6px) !important;
}
</style>
