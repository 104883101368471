var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visiable
    ? _c(
        "en-dialog",
        {
          attrs: {
            title: "选择对比人",
            visible: _vm.visiable,
            width: "900px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visiable = $event
            },
            close: _vm.closeFn,
          },
        },
        [
          _c("en-tabs", {
            attrs: { list: _vm.tabList, type: "white" },
            on: { change: _vm.changeTabs },
            model: {
              value: _vm.activeTab,
              callback: function ($$v) {
                _vm.activeTab = $$v
              },
              expression: "activeTab",
            },
          }),
          _c(
            "div",
            { staticClass: "user-list" },
            _vm._l(_vm.personList, function (item) {
              return _c(
                "div",
                { key: item.id, staticClass: "mods" },
                [
                  _c("el-checkbox", {
                    staticStyle: { "margin-right": "10px" },
                    model: {
                      value: item.checked,
                      callback: function ($$v) {
                        _vm.$set(item, "checked", $$v)
                      },
                      expression: "item.checked",
                    },
                  }),
                  _c("en-user-logo", {
                    attrs: { "user-name": item.name, size: "32px" },
                  }),
                  _c("span", { staticClass: "names" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                  _vm.activeTab === "1" && item.id !== "mine"
                    ? _c("en-icon", {
                        staticClass: "del-icon",
                        attrs: { name: "iconshanchu-liebiao", size: "small" },
                        nativeOn: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.deleteFrequent(item)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "en-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeTab === "1",
                      expression: "activeTab==='1'",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.saveCompare },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "en-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeTab === "2",
                      expression: "activeTab==='2'",
                    },
                  ],
                  attrs: { type: "white" },
                  on: { click: _vm.addCompare },
                },
                [_vm._v("添加常用")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }