var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visiable
    ? _c(
        "en-dialog",
        {
          staticClass: "base-data-filter",
          attrs: {
            visible: _vm.visiable,
            title: "选择基础数据",
            width: "900px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visiable = $event
            },
            close: _vm.closeFn,
          },
        },
        [
          _c(
            "div",
            { staticClass: "form-wrap" },
            [
              _c("en-transfer-data", {
                attrs: { config: _vm.getConfig() },
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [_c("en-button", { on: { click: _vm.save } }, [_vm._v(" 确定 ")])],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }