<!--
 * @Author: zoujp
 * @Date: 2020-11-23 18:18:53
 * @LastEditTime: 2021-08-16 14:58:15
 * @LastEditors: zhulin
 * @Description: 选基础数据内容-穿梭框
 * @FilePath: \user\src\views\themeSet\components\selectBaseData.vue
-->
<template>
  <en-dialog
    v-if="visiable"
    :visible.sync="visiable"
    title="选择基础数据"
    class="base-data-filter"
    width="900px"
    :close-on-click-modal="false"
    @close="closeFn"
  >
    <div class="form-wrap">
       <en-transfer-data v-model="value" :config="getConfig()"></en-transfer-data>
    </div>
    <div slot="footer">
      <en-button @click="save">
        确定
      </en-button>
    </div>
  </en-dialog>
</template>

<script>
import { cloneDeep } from "lodash";
import enTransferData from "@/components/en-transfer-data";

export default {
  name: "BaseDataComm",
  components: { enTransferData },
  props: {
    filterRowData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      visiable: false,
      value: [],
      curBaseData: {}
    };
  },
  mounted() {
  },
  watch: {
    visiable(val) {
      if (val) {
        this.curBaseData = this.formatData(this.filterRowData);
      }
    }
  },
  methods: {
    /**
     * @description: 获取穿梭框配置
     * @param {*}
     * @return {*}
     */
    getConfig() {
      const atd = this.curBaseData;
      return {
        isMulti: true, // 是否多选
        lazy: true, // 获取树形结构静态数据  请设置false  默认为true  dataSource == 11 默认设置false
        choiceRule: false, // 禁止勾选父项
        selectMode: "tree",
        showTitle: false,
        exclude: false, // 是否需要显示排除按钮
        defaultProps: {
          label: "name",
          value: "id"
        },
        dependData: {
          dataSource: atd.dataSource,
          dataList: atd.dataList || [],
          areaObjType: atd.areaObjType,
          areaObjTypeId: atd.areaObjTypeId,
          templateId: atd.templateId
        }
      };
    },
    /**
     * @description: 格式化数据
     * @param {*} data
     * @return {*}
     */
    formatData(data) {
      const atd = cloneDeep(data);
      this.value = [];
      // 特殊处理一下  主题 筛选 的 数据源字段 v4.3
      if ((atd.dataSource || "").toString() === "3") {
        atd.dataSource = 11;
        atd.templateId = atd.sourceTemplateId || atd.templateId;
        if (atd.field === "dataSource") {
          atd.dataList = typeof atd.selContent === "string" ? JSON.parse(atd.selContent) : atd.selContent;
        } else {
          atd.dataList = typeof atd.dataArea === "string" ? JSON.parse(atd.dataArea) : atd.dataArea;
        }
      }

      // 特殊处理主题状态字段 v4.8.3 py
      if ((atd.sourceField || "").toUpperCase() === "BILLSTATUS") {
        atd.field = "billStatus";
      }

      if ((atd.dataSource || "").toString() === "2") {
        atd.templateId = atd.areaObjTypeId || atd.templateId;
      }
      if (atd.conSet.input5) {
        this.value = (() => {
          const vs = [];
          const v = atd.conSet.input5.split(",");
          const t = atd.conSet.input5Text.split(",");
          v.forEach((b, a) => {
            vs.push({ id: b, name: t[a] });
          });
          return vs;
        })();
      }
      return atd;
    },
    /**
     * @description: 保存
     * @param {*}
     * @return {*}
     */
    save() {
      const value = []; const valueText = [];
      this.value.forEach((b) => {
        value.push(b.id);
        valueText.push(b.name);
      });
      this.$emit("saveBaseData", { value, valueText, field: this.curBaseData.field });
      this.visiable = false;
    },
    /**
     * @description: 关闭弹窗
     * @param {*}
     * @return {*}
     */
    closeFn() {
      this.visiable = false;
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .en-transfer .en-transfer-panel-body.with-search{
  height: calc(100% - 51px);
}
/deep/ .en-transfer{
  min-height:300px;
}
.base-data-filter {
  & /deep/ .el-dialog__header {
    text-align: left;
  }
}
</style>
