<template>
  <div class="en-time-table">
    <el-table :data="tableData" height="100%" border stripe row-class-name="no-hover">
      <template slot="append">
        <div class="time-line" :style="{ width: 331 * 6 + 'px', top: nowTimeTop + 'px' }"></div>
        <div class="shadow-line"></div>
      </template>
      <el-table-column prop="date" label="" width="70" fixed="left">
        <template slot-scope="scope">
          <div class="time" :style="scope.$index === 55 ? { color: '#F76B6B' } : {}">
            {{ timeShowWithScope(scope) }}
          </div>
          <div v-if="scope.$index === 0" class="highlight-time" :style="{ top: nowTimeTop + 'px' }">
            {{ nowTime }}
          </div>
        </template>
      </el-table-column>
      <el-table-column v-for="(item, i) in weekData" :key="i" :label="item.name">
        <template slot-scope="scope">
          <div v-if="scope.$index === 0" class="block">
            <div v-if="item.list && item.list.length">
              <time-resource
                v-for="(mod, imod) in item.list"
                :item="mod" :key="imod"
                :time="mod[timeField]"
                :time-field="timeField"
                :list="item.list"
                type="blue"
                @viewPeriodList="viewPeriodList"
                @viewDetail="viewDetail"
                @secret="secret"
                @del="del"
                @readMsg="readMsg"
                @finish="finish"
              />
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <business-dialog :business-params="businessParams" v-if="businessDialogVisible" :dialog-visible.sync="businessDialogVisible"></business-dialog>
    <el-drawer
      title="我的任务"
      :visible.sync="drawer"
      :size="400"
      direction="rtl"
     >
      <div>
         <time-resource
            v-for="(mod, imod) in periodList"
            :item="mod" :key="imod"
            :time="mod[timeField]"
            :time-field="timeField"
            :should-position-abs="false"
            type="blue"
            @viewDetail="viewDetailByDrawer"
            @secret="secret"
            @del="del"
            @readMsg="readMsg"
            @finish="finish"
          />
      </div>
    </el-drawer>
  </div>
</template>
<script>
import dayjs from "dayjs";
import BusinessDialog from "@/components/businessDetail/businessDialog.vue";
import { viewBusinessDialogParams } from "@/components/businessDetail/helper";
import { defaultListService } from "@/api";
import { enService } from "@/api/en/index";
import TimeResource from "./TimeResource";

export default {
  name: "EnTimeTable",
  components: { TimeResource, BusinessDialog },
  props: {
    // 资源列宽
    columnWidth: {
      type: Number,
      default: 331
    },
    templateId: {
      type: String
    },
    changeDate: {
    },
    view: {
      type: String
    },
    startD: {
      type: String
    },
    endD: {
      type: String
    },
    timeField: {
      type: String
    },
    data: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      tableData: [],
      weekData: [],
      nowTime: "",
      nowTimeTop: "",
      // 时间段任务列表
      periodList: [],
      drawer: false,
      businessDialogVisible: false
    };
  },
  watch: {
    view(newval) {
      if (newval) {
        this.initView();
      }
    },
    changeDate() {
      this.getDateInfo();
    },
    data: {
      immediate: true,
      handler(newval) {
        if (newval) {
          this.initView();
        }
      }
    },
    businessDialogVisible(newVal) {
      if (!newVal) {
        this.$emit("reload");
      }
    }
  },
  mounted() {
    this.initView();
    this.updateNowTime();
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    viewPeriodList(list) {
      if (this.weekData.length > 2) {
        this.drawer = true;
        this.periodList = list;
      }
    },
    viewDetailByDrawer(item) {
      this.viewDetail(item, true);
    },
    viewDetail(item, flag = false) {
      if ((!this.drawer || flag) && item.businessKey && [3, 22].includes(item.refType)) {
        this.businessParams = viewBusinessDialogParams({
          templateId: item.templateId,
          id: item.businessKey,
          businessType: 2
        });
        this.businessDialogVisible = true;
      }
    },
    /**
     * @description: 私密
     * @param {*} item
     * @return {*}
     */
    secret(item) {
      const params = {
        templateId: this.templateId,
        id: item.id, // 业务建模数据ID
        refType: item.refType,
        secretType: item.secretType.toString() === "1" ? 2 : 1// Number1-私密、2-公开
      };
      this.$confirm(`确定要${item.secretType.toString() === "1" ? "取消私密" : "设为私密"}吗？`, "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.secretSubmit(item, params);
        });
    },
    /**
     * @description: 私密提交
     * @param {*}
     * @return {*}
     */
    async secretSubmit(item, params) {
      await defaultListService.ptSecret(params);
      this.$message("操作成功");
      // this.$set(item, "secretType", item.secretType.toString() === "1" ? 2 : 1);
      // this.$forceUpdate();
      this.$emit("reload");
    },
    /**
     * @description: 删除
     * @param {*} item
     * @return {*}
     */
    del(item) {
      const params = {
        templateId: this.templateId, // 主题模板ID
        id: item.id// 业务建模数据ID
      };
      this.$confirm("确定要删除吗？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.delSubmit(params);
        });
    },
    /**
     * @description: 删除提交
     * @param {*}
     * @return {*}
     */
    async delSubmit(params) {
      await defaultListService.ptOut(params);
      this.$message("操作成功");
      // // eslint-disable-next-line
      // for (const key in this.data) {
      //   this.data[key].forEach((a, b) => {
      //     if (params.id === a.id) {
      //       this.data[key].splice(b, 1);
      //     }
      //   });
      // }
      // this.$forceUpdate();
      this.$emit("reload");
    },
    /**
     * @description: 读取消息
     * @param {*}
     * @return {*}
     */
    async readMsg(item) {
      if (item.refType === 23 && item.readFlag === "000") { // 消息中心
        const params = {
          ids: item.id, isBatch: 1
        };
        await enService.readNotice(params);
        // this.$set(item, "readFlag", "001");
        this.$emit("reload");
        // this.$forceUpdate();
      }
    },
    /**
     * @description: 完成操作
     * @param {*}
     * @return {*}
     */
    finish(item) {
      let msg = "确定要标记为$$完成吗?";
      if (!item.isChecked) {
        msg = msg.replace("$$", "未");
      } else {
        msg = msg.replace("$$", "");
      }
      const params = {
        templateId: this.templateId,
        id: item.id,
        refType: item.refType,
        finishType: item.isChecked ? 1 : 2
      };
      this.$confirm(msg, "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.finishSubmit(item, params);
        }).catch(() => {
          this.$set(item, "finishType", item.finishType);
          this.$set(item, "isChecked", !item.isChecked);
          this.$forceUpdate();
        });
    },
    /**
     * @description: 完成操作提交
     * @param {*}
     * @return {*}
     */
    async finishSubmit(item, params) {
      await defaultListService.ptFinish(params);
      this.$message("操作成功！");
      // this.$set(item, "finishType", params.finishType);
      // this.$set(item, "isChecked", item.isChecked);
      this.$emit("reload");
      // this.$forceUpdate();
    },
    updateNowTime() {
      const updateTime = () => {
        this.nowTimeTop = dayjs(new Date()).hour() * 46 + dayjs(new Date()).minute() * 0.7;
        this.nowTime = dayjs(new Date()).format("HH:mm");
      };
      updateTime();
      this.timer = setInterval(updateTime, 10000);
    },
    initView() {
      this.getDateInfo();
      console.log(JSON.stringify(this.weekData, null, 2));
      this.$nextTick(() => {
        for (let i = 0; i < this.weekData.length; i++) {
          // eslint-disable-next-line
          for (const key in this.data) {
            console.log("key", key);
            console.log("time", this.weekData[i].time);
            this.$set(this.weekData[i], "list", []);
            if (key.toString() === this.weekData[i].time.toString()) {
              this.$set(this.weekData[i], "list", this.data[key]);
            }
          }
        }
        this.$forceUpdate();
      });
    },
    /**
     * @description 获取时间
     */
    getDateInfo() {
      this.tableData = [];
      this.weekData = [];
      this.tableData = new Array(24);
      this.tableData.fill({});
      const d = this.changeDate;
      const week = ["日", "一", "二", "三", "四", "五", "六"]; const w = [];
      if (this.view === "day") {
        this.weekData = [
          {
            name: `${week[dayjs(d).day()]} ${dayjs(d).format("MM-DD")}`,
            time: d,
            list: []
          }
        ];
      }
      if (this.view === "week") {
        let curD = this.startD;
        for (let i = 0; i < week.length; i++) {
          w.push({ name: `${week[i]} ${dayjs(curD).format("MM-DD")}`, time: curD, list: [] });
          curD = dayjs(curD).add(1, "day").format("YYYY-MM-DD");
        }
        this.weekData = w;
      }
    },
    /**
     * @description: 渲染左侧时间
     */
    timeShowWithScope({ $index }) {
      return $index < 9 ? `0${$index + 1}:00` : `${$index + 1}:00`;
    }
  }
};
</script>
<style lang="scss" scoped>
.en-time-table {
  height: 100%;
  // overflow-y: scroll;
  position: relative;
  /deep/ .el-table__cell{
    background-color: #fff !important;
  }
  .highlight-time {
    position: absolute;
    color: #f76b6b;
    left: 0;
    z-index: 22;
    width: 70px;
    text-align: center;
  }
  // 阴影
  & /deep/ .el-table__header-wrapper::after,
  & /deep/ .el-table__fixed-header-wrapper::after {
    content: "";
    position: absolute;
    top: 50px;
    height: 10px;
    width: 100%;
    box-shadow: inset 0px 15px 10px -15px rgba($color: #000000, $alpha: 0.2);
    z-index: 200;
  }
  // 时间红线
  .time-line {
    position: absolute;
    margin-left: 70px;
    height: 1px;
    background-color: #f76b6b;
    left: 0;
    top: 145px;
    z-index: 2;
    &::before {
      content: "";
      border: 3px solid #f76b6b; /*设置红色*/
      border-radius: 3px; /*设置圆角*/
      position: absolute;
      z-index: 2;
      left: 0%;
      margin-right: -5px;
      margin-top: -3px;
    }
  }
  // 去掉hover
  & /deep/ .no-hover:hover > td {
    background-color: initial !important;
  }
  .el-table {
    width: 100%;
    // 这个控制红线的位置能否展示出来
    & /deep/ .el-table__append-wrapper {
      overflow: auto;
    }
    & /deep/ thead {
      font-size: 12px;
      color: #666;
      font-weight: normal;
    }
    & /deep/ .el-table__row /deep/ td,
    & /deep/ .el-table__row /deep/ th {
      padding: 0px 0px;
      height: 46px;
    }
    & /deep/ .el-table__header td,
    & /deep/ .el-table__header th {
      padding: 0px 0px;
      height: 48px;
      background: rgba(246, 250, 253, 1);
      border-bottom: 0;
      text-align: center;
      // box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1) !important;
    }
    & /deep/ .el-table__row td:first-child {
      border-left: 0;
      border-bottom: 0;
      background-color: #fff;
    }
    .time {
      position: absolute;
      width: 70px;
      text-align: center;
      left: 0;
      top: 35px;
      z-index: 11;
    }
  }
}
.en-date-picker-header {
  display: flex;
  justify-content: flex-start;
  line-height: 44px;
  font-size: 12px;
  background-color: #f7f9f9;
  color: #636c78;
  border-bottom: 1px solid #e8ecf2;
  cursor: pointer;
  .en-icon {
    width: 12px;
    height: 12px;
    margin: 16px 5px;
    &:nth-last-child(1) {
      transform: rotate(180deg);
    }
  }
  .el-date-editor {
    /deep/ .el-input__inner {
      padding: 0;
      text-align: center;
      width: 110px;
      border: 0;
      background: 0;
    }
    /deep/ .el-input__prefix {
      display: none;
    }
  }
}
</style>
