<!--
 * @Author: zhulin
 * @Date: 2020-08-22 16:33:02
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-11-19 15:21:50
 * @Description: 主题-共享人通用
 * @FilePath: \user\src\views\themeSet\components\shareComm.vue
-->
<template>
  <div class="share-main">
    <div class="txts-main">
      <span class="txt-mine" v-if="!shareUserId" >我的{{ templateName }}</span>
      <span v-else>
        <span class="txt-backmine" @click="backmine">我的</span>
        <span class="txt-other">{{ shareUserName }}的{{ templateName }}</span>
      </span>
      <en-icon name="iconjiantou-wodezhuti" size="16" v-if="shareUsers && shareUsers.length" @click.native="changeShare" class="share-icon"></en-icon>
    </div>
    <alertShare :show="showShareAlert" :shareUsers="shareUsers" :templateId="templateId" @toShow="toShow" @refreshData="refreshData"></alertShare>
  </div>
</template>
<script>
import { themeCommonService } from "@/api";
import alertShare from "./alertShare";

export default {
  name: "shareComm",
  components: {
    alertShare
  },
  props: {
    templateName: {
      type: String
    },
    templateId: {
      type: String
    }
  },
  mounted() {
    this.queryShareUser();
  },
  data() {
    return {
      shareUserId: "",
      shareUserName: "",
      shareUsers: [],
      isOthers: false,
      showShareAlert: false
    };
  },
  methods: {
    /**
     * @description: 查询分享用户
     */
    async queryShareUser() {
      const rsp = await themeCommonService.queryShareUser({ templateId: this.templateId });
      const arr = rsp || [];
      this.shareUsers = arr;
    },
    /**
     * @description: 触发共享人弹窗
     */
    changeShare() {
      this.showShareAlert = true;
    },
    /**
     * @description: 关闭弹窗
     */
    toShow() {
      this.showShareAlert = false;
    },
    /**
     * @description: 更新数据
     */
    refreshData(data) {
      this.shareUserId = data.id;
      this.shareUserName = data.name;
      this.$emit("saveCallback", { shareUserId: this.shareUserId, shareUserName: this.shareUserName });
    },
    /**
     * @description: 返回我的
     */
    backmine() {
      this.shareUserId = "";
      this.shareUserName = "";
      this.$emit("saveCallback", { shareUserId: this.shareUserId, shareUserName: this.shareUserName });
    }
  }
};
</script>
<style lang="scss" scoped>
.share-main{
}
.txt-mine{
  text-align: center;
  color: #409eff;
}
.txts-main{
  text-align: center;
}
.btn-list {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  .mods {
    margin-right: 8px;
    cursor: pointer;
  }
}
.add-btn {
  display: inline-block;
  vertical-align: middle;
}
.txt-backmine {
  border-right: 1px solid #f3f3f3;
  padding-right: 10px;
  margin-right: 10px;
  cursor: pointer;
}
.txt-other{
  color: #409eff;
}
.share-icon {
  display: inline-block;
  vertical-align: middle;
  color: #aeaeae;
  cursor: pointer;
}
</style>
