<!--
 * @Author: zoujp
 * @Date: 2020-11-19 17:29:29
 * @LastEditTime: 2021-08-20 10:24:54
 * @LastEditors: zhulin
 * @Description: 主题 - 条件过滤
 * @FilePath: \user\src\views\themeSet\components\filterComm.vue
-->
<template>
  <en-dialog
    v-if="visiable"
    :visible.sync="visiable"
    title="筛选"
    class="theme-filter"
    width="900px"
    :close-on-click-modal="false"
    @close="closeFn"
  >
    <div class="tab-wrap" v-show="showCompare">
      <en-tabs
        :list="tabList"
        type="white"
        v-model="activeTab"
        @change="changeTabs"
      >
      </en-tabs>
    </div>
    <div class="compare-wrap" v-show="activeTab==1 && showCompare">
      <en-table
        ref="comptable"
        :data="cList"
        auto-resize
        :select-config="{checkField:'isChecked'}"
        @select-change="handleSelectChange"
      >
        <en-table-column type="index" width="60"></en-table-column>
        <en-table-column title="字段" field="name" width="150">
        </en-table-column>
        <en-table-column  type="selection" width="635">
        </en-table-column>
      </en-table>
      <!-- <div class="check-wrap">
        <el-checkbox v-model="compDefault">设为默认筛选</el-checkbox>
      </div> -->
    </div>
    <div class="form-wrap" v-show="activeTab==2">
      <en-table :data="fList" auto-resize >
        <en-table-column type="index" width="60"></en-table-column>
        <en-table-column title="字段" field="name" width="150">
        </en-table-column>
        <en-table-column title="筛选范围" width="635">
          <!--自定义单元格-->
          <template #edit="{ row }">
            <div v-if="row.fieldType === 1 || row.fieldType === 2" class="edit-col-wrap">
              <en-select class="mod" v-model="row.conSet.opType" placeholder="请选择" :data="getOptions(row.fieldType)" @change="opChange" :props="opProps"></en-select>
              <el-input class="mod" v-show="setShow(row)" v-model="row.conSet.input1" placeholder="请输入"> </el-input>
            </div>
            <div v-else-if="row.fieldType === 3" class="edit-col-wrap">
              <en-input-range
                class="mod"
                v-model="row.conSet.input3"
                :max="99999999"
                :min="0"
                rangeSeparator="至"
              >
              </en-input-range>
            </div>
            <div v-else-if="row.fieldType === 4" class="edit-col-wrap">
                <en-input-range
                  class="mod"
                  v-model="row.conSet.input4"
                  :precision="2"
                  :max="99999999"
                  :min="0"
                  thousand-format
                  rangeSeparator="至"
                >
                </en-input-range>
            </div>
            <div v-else-if="row.fieldType === 5 && (row.areaObjType==='001' || row.areaObjType==='002')" class="edit-col-wrap">
              <en-select class="mod"
                v-model="row.conSet.opType"
                placeholder="请选择"
                :data="getOptions(row.fieldType)"
                @change="opChange"
                :props="opProps"
              ></en-select>
              <en-select class="mod"
                v-show="setShow(row)"
                v-model="row.conSet.variable"
                placeholder="请选择"
                :data="getVariableList(row)"
                @change="valChange"
                :props="valProps"
              ></en-select>
              <el-input class="mod"
                v-show="row.conSet.variable==='0' && row.conSet.variable!=='' && setShow(row)"
                v-model="row.conSet.input5Text" placeholder="请选择内容"
                readonly
                @click.native.stop="selectBdComm(row)"
              > </el-input>
            </div>
            <div v-else-if="row.fieldType === 5 && row.areaObjType!=='001' && row.areaObjType!=='002'" class="edit-col-wrap">
              <en-select class="mod"
                v-model="row.conSet.opType"
                placeholder="请选择"
                :data="getOptions(row.fieldType)"
                @change="opChange"
                :props="opProps"
              ></en-select>
              <el-input class="mod"
                v-show="row.conSet.opType !== '13' && row.conSet.opType !== '14' && row.conSet.opType !== ''"
                v-model="row.conSet.input5Text" placeholder="请选择内容"
                readonly
                @click.native.stop="selectBdComm(row)"
              > </el-input>
            </div>
            <div v-else-if="row.fieldType === 7" class="edit-col-wrap">
              <en-select
                class="mod" :style="row.conSet.variable==='0'?'width:110px !important;':'flex:1 !important;'"
                v-model="row.conSet.variable" placeholder="请选择"
                :data="getVariableList(row)"
                @change="valChange"
                :props="valProps"
              ></en-select>
              <div class="date7-wrap" v-show="row.conSet.variable==='0'">
                <el-date-picker
                  v-show="!row.conSet.curStart"
                  v-model="row.conSet.startTime"
                  :type="getFormat(row.timeFormat)"
                  :value-format="getValueFormat(row.timeFormat)"
                  placeholder="请选择">
                </el-date-picker>
                <el-checkbox v-model="row.conSet.curStart" style="margin-left:10px;">当前</el-checkbox>
                <el-date-picker
                  v-show="!row.conSet.curEnd"
                  v-model="row.conSet.endTime"
                  :type="getFormat(row.timeFormat)"
                  :value-format="getValueFormat(row.timeFormat)"
                  placeholder="请选择">
                </el-date-picker>
                <el-checkbox v-model="row.conSet.curEnd" style="margin-left:10px;">当前</el-checkbox>
              </div>
            </div>
          </template>
        </en-table-column>
      </en-table>
      <!-- <div class="check-wrap">
        <el-checkbox v-model="filterDefault">设为默认筛选</el-checkbox>
      </div> -->
    </div>
    <div slot="footer">
      <en-button type="white" @click="clear">
        清空
      </en-button>
      <en-button @click="save">
        确定
      </en-button>
    </div>
  </en-dialog>
</template>
<script>
// import dayjs from "dayjs";
import { cloneDeep } from "lodash";
import { defaultListService } from "@/api";
/*eslint-disable*/

Date.prototype.addYear = function (num) {
    if (!num) {
        return this;
    }
    num = Number((num + "").replace(/,/g, ""));
    let tempDate = this.getDate();
    this.setYear(this.getFullYear() + num);
    if (tempDate != this.getDate()) {
        this.setDate(0);
    }
    return this;
};
export default {
  name: "FilterComm",
  components: { },
  props: {
    fieldList: {
      type: Array,
      default() {
        return [];
      }
    },
    compList: {
      type: Array,
      default() {
        return [];
      }
    },
    searchParams: {
      type: Array,
      default() {
        return [];
      }
    },
    compParams: {
      type: Array,
      default() {
        return [];
      }
    },
    setBaseData: {
      type: Object,
      default() {
        return {};
      }
    },
    showCompare:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      visiable: false,
      fList: [], //  筛选字段集合
      cList:[],// 对比字段集合
      var1: [], // 人员全局变量
      var2: [], // 机构全局变量
      var3: [], // 日期全局变量
      varIsLoad: false,
      opProps: { value: "value", label: "label" },
      valProps: { value: "id", label: "name" },
      sysdate:"1800-00-00 00:00:00",
      tabList:[
        {
          id:"1",name:"对比设置"
        },
        {
          id:"2",name:"范围设置"
        }
      ],
      activeTab:"1",
      compDefault:false,
      filterDefault:false,
      checked:[]
    };
  },
  mounted() {
    if(!this.showCompare){
      this.activeTab = "2";
    }else{
      this.initEditDataComp();
    }
    this.getVariable();
  },
  watch: {
    fieldList() {
      this.setFilterDefault();
      this.getVariable();
    },
    compList() {
      this.setCompDefault();
      this.initEditDataComp();
    },
    visiable(val) {
      if(val){
        this.getVariable();
      }
    },
    searchParams(val){
      this.setFilterDefault();
      this.initEditData();
    },
    compParams(val){
      this.setCompDefault();
      this.initEditDataComp();
    },
    setBaseData(val){
      this.initBaseData(val);
    }
  },
  methods: {
    /**
     * @description: 设置对比默认设置
     * @param {*}
     * @return {*}
     */
    setCompDefault(){
      if(this.showCompare){
        this.compDefault = this.compParams.length > 0;
      }
    },
    /**
     * @description: 设置筛选默认设置
     * @param {*}
     * @return {*}
     */
    setFilterDefault(){
      this.filterDefault = this.searchParams.length > 0;
    },
    /**
     * @description: 对比选中checked回调事件
     * @param {*} checked
     * @param {*} row
     * @param {*} selection
     * @return {*}
     */
    handleSelectChange ({ checked ,row, selection }){
      //row.isChecked = checked;
    },

    /**
     * @description: 切换tab
     * @param {*}
     * @return {*}
     */
    changeTabs(){

    },
    //清空
    clear(){
      this.fList.forEach((item)=>{
        item.conSet.opType="";
        item.conSet.variable="";
        item.conSet.input1="";
        item.conSet.input5="";
        item.conSet.input5Text="";
        item.conSet.input3="";
        item.conSet.input4="";
        item.conSet.startTime="";
        item.conSet.endTime="";
        item.conSet.curStart=false;
        item.conSet.curEnd=false;
      });
    },
    /**
     * @description: 选择基础数据后填充数据
     * @param {*}
     * @return {*}
     */
    initBaseData(obj){
      let that = this;
      this.fList.forEach((item)=>{
         if(item.field === obj.field){
            that.$set(item.conSet,'input5',obj.value.join(","))
            that.$set(item.conSet,'input5Text',obj.valueText.join(","))
         }
      });
    },
    initEditDataComp(){
      this.cList = cloneDeep(this.compList);
      for(let i=0;i<this.cList.length;i++){
        this.cList[i].isChecked=false;
        for(let j=0;j<this.compParams.length;j++){
          if(this.compParams[j].field === this.cList[i].field){
            this.cList[i].isChecked = true;
            continue;
          }
        }
      }
      this.$forceUpdate();
    },
    /**
     * @description: 初始化编辑的数据
     * @param {*}
     * @return {*}
     */
    initEditData(){
      let sParams = cloneDeep(this.searchParams);
      sParams.forEach((item)=>{
        this.fList.forEach((itm,ind)=>{
          // console.log("this.fList",itm.fieldType);
          if(item.nameVariable === itm.field){
            itm.conSet.opType = item.opType;
            if(item.fieldType === 1 || item.fieldType === 2){
              itm.conSet.input1 = item.value;
            }else if(item.fieldType === 3 || item.fieldType === 4){
              itm.conSet.opType = "";
              let v = [];
              if(item.value){
                let arr = item.value.split(",");
                v.push(Number(arr[0]||0));
                v.push(Number(arr[1]||0));
              }
              if(item.fieldType === 3){
                itm.conSet.input3 = v;
              }else{
                itm.conSet.input4 = v;
              }
            }else if(item.fieldType === 5){
              if(item.valueType === "30"){
                itm.conSet.variable = "0";
                itm.conSet.input5 = item.value;
                itm.conSet.value5Text = item.valueText;
                itm.conSet.input5Text = item.valueText;
              }else{
                itm.conSet.variable = item.value;
              }
            }else if(item.fieldType === 7){
              if(item.valueType === "30"){
                itm.conSet.variable = "0";
                let times = item.value.split(",");
                if(times[0]){
                  if(times[0] === this.sysdate){
                    itm.conSet.curStart = true;
                  }else{
                    itm.conSet.startTime = times[0];
                  }
                }
                if(times[1]){
                  if(times[1] === this.sysdate){
                    itm.conSet.curEnd = true;
                  }else{
                    itm.conSet.endTime = times[1];
                  }
                }
              }else{
                itm.conSet.variable = item.value;
              }
            }
          }
        });
      });
    },
    /**
     * @description: 设置value框是否显示
     * @param {*}
     * @return {*}
     */
    setShow(row) {
      if (row.conSet.opType === "" || row.conSet.opType === "13" || row.conSet.opType === "14") {
        return false;
      } return true;
    },
    /**
     * @description: 选择类型为5的基础数据
     * @param {*} row
     * @return {*}
     */
    selectBdComm(row) {
      this.$emit("showBaseData", row);
    },
    /**
     * @description: 格式化时间格式
     * @param {*} format
     * @return {*}
     */
    getValueFormat(format){
      format = (format || 8).toString();
      if (format === "12") {
        return "yyyy";
      } if (format === "1") {
        return "yyyy-MM";
      } if (format === "9" || format === "10") {
        return "yyyy-MM-dd HH:mm:ss";
      }
      return "yyyy-MM-dd";
    },
    /** 日期格式化
     * @description:
     * @param format： 12(年) 1(月) 8(年月日) 9(年月日时分) 10(年月日时分秒)
     * @return {*}
     */
    getFormat(format) {
      format = (format || 8).toString();
      if (format === "12") {
        return "year";
      } if (format === "1") {
        return "month";
      } if (format === "9" || format === "10") {
        return "datetime";
      }
      return "date";
    },
    /**
     * @description: 获取全局变量
     * @param {*}
     * @return {*}
     */
    getVariableList(row) {
      if (row.areaObjType === "002") {
        return this.val1;
      } if (row.areaObjType === "001") {
        return this.val2;
      } if (row.fieldType.toString() === "7") {
        return this.val3;
      }
      return [];
    },
    /**
     * @description: 对字段数据特殊处理用于
     * @return {*}
     */
    formatFieldList() {
      const lisClone = cloneDeep(this.fieldList);
      lisClone.forEach((item) => {
        item.conSet = {
          opType: "", // 操作符（1,2类型用到）
          variable: "", // 全局变量（5,7类型用）
          input1: "", // 输入框（1,2类型用）
          input5: "", // 输入框选基础数据（5类型用）
          input5Text: "", // 输入框选基础数据（5类型用）
          input3: [], // 区间输入框（3类型用）
          input4: [], // 区间输入框（4类型用）
          startTime: "", // 开始日期
          endTime: "", // 结束日期
          curStart: false, // 开始勾选框()
          curEnd: false // 结束勾选框()
        };
      });
      this.fList = lisClone;
      // bug37597
      let arrs = [1,2,3,4,5,7];
      this.fList.forEach((itm,ind)=>{
        if (!arrs.includes(itm.fieldType)) {
          this.fList.splice(ind, 1);
        }
      })
      this.initEditData();
    },
    /**
     * @description: 获取操作符列表
     * @fieldType :字段类型
     * @return {*}
     */
    getOptions(fieldType) {
      if (fieldType === 1 || fieldType === 2) {
        return [{ value: "1", label: "等于" }, { value: "2", label: "不等于" }, { value: "3", label: "包含" }, { value: "4", label: "不包含" }, { value: "13", label: "不为空" }, { value: "14", label: "为空" }];
      } if (fieldType === 5) {
        return [{ value: "9", label: "不包含于" }, { value: "10", label: "包含于" }, { value: "13", label: "不为空" }, { value: "14", label: "为空" }];
      }
      return [];
    },
    /**
     * @description: 操作符下拉回调
     * @fieldType :字段类型
     * @return {*}
     */
    opChange() {
      console.log(1);
    },
    /**
     * @description: 全局变量下拉回调
     * @fieldType :字段类型
     * @return {*}
     */
    valChange() {
      console.log(2);
    },
    /**
     * @description: 获取全局变量
     * @param {*}
     * @return {*}
     */
    async getVariable() {
      if (!this.varIsLoad) {
        const res1 = await defaultListService.queryRbacGloableVariableList({ type: 1 });
        const res2 = await defaultListService.queryRbacGloableVariableList({ type: 2 });
        const res3 = await defaultListService.queryRbacGloableVariableList({ type: 3 });
        this.val1 = this.filterVal(res1);
        this.val2 = this.filterVal(res2);
        this.val3 = this.filterVal(res3);
        this.varIsLoad = true;
      }
      this.formatFieldList();
    },
    /**
     * @description: 过滤全局变量类型，1人员2机构3时间
     * @param {*} data
     * @return {*}
     */
    filterVal(data) {
      return data.filter((item) => item.isUser === 0);
    },
    /**
     * @description: 获取全局变量名称
     * @param {*} item
     * @return {*}
     */
    getVaiableText(item){
      let res="";
      if(item.fieldType === 7){
        this.val3.forEach((b)=>{
          if(b.id === item.conSet.variable){
            res = b.name;
          }
        });
      }else if(item.fieldType === 5){
        let arr = item.areaObjType === "002"?this.val1:this.val2;
        arr.forEach((b)=>{
          if(b.id === item.conSet.variable){
            res = b.name;
          }
        });
      }
      return res;
    },
    /**
     * @description: 保存
     */
    save() {
      const filterData = [];
      const compData = [];
      let has = false;
      let msg = "";
      for (let i = 0, len = this.fList.length; i < len; i++) {
        const item = this.fList[i];
        const set = item.conSet;
        const cp = {
            nameVariable: item.field,
            fieldType: item.fieldType,
            fieldName: item.name || "",
            areaObjType: item.areaObjType,
            areaObjTypeId: item.areaObjTypeId,
            parentField: item.parentField || "",
            parentFieldType: item.parentFieldType || "",
            valueType: "2", //固定值
            opType: "",
            value:"",
            valueText: "",
            timeFormat: item.timeFormat || "8",
            field:item.field,
            name:item.name
        };
        if (item.fieldType === 1 || item.fieldType === 2) {
          if (!set.opType && !set.input1) { continue; }
          if(set.opType && set.opType !== "13" && set.opType !== "14"){
              cp.value = set.input1;
              cp.valueText = set.input1;
              cp.opType = set.opType;
              filterData.push(cp);
          }else{
              cp.opType = set.opType;
              filterData.push(cp);
          }
          if(set.input1 && !set.opType){
              has = true;
              msg = "存在未选择操作符的行";
              break;
          }
        }else if(item.fieldType === 3 || item.fieldType === 4){
          cp.opType = "";
          const arr = item.fieldType === 3?set.input3:set.input4;
          const s_val = arr[0] || "";
          const e_val = arr[1] || "";
          if (s_val && e_val && Number(s_val) > Number(e_val)) {
              has = true;
              msg = "存在开始" + (se_type === 3 ? "数值" : "金额") + "大于结束" + (se_type === 3 ? "数值" : "金额") + "的行";
              break;
          }

          if(s_val || e_val){
              cp.value = [s_val, e_val].join(",");
              if(!s_val){
                  cp.valueText = '<='+e_val;
              }else if(!e_val){
                  cp.valueText = '>='+s_val;
              }else{
                  cp.valueText = [s_val, e_val].join(",");
              }
              filterData.push(cp);
          }
        } else if (item.fieldType === 5) {
          cp.opType = set.opType;
          if(!set.opType && set.variable === ""){continue;}
            if(set.opType !== "13" && set.opType !== "14"){
                //默认筛选条件不在选项内
                if(set.variable && set.variable !== "0" && set.variable !== "1"){
                    cp.value = set.variable;
                    cp.valueText = this.getVaiableText(item);
                    cp.valueType = "3";
                }else{
                    if(set.input5){
                        cp.value = set.input5;
                        cp.valueText = set.input5Text;
                        cp.valueType = "30";
                    }
                }
                if(!set.opType && set.variable !== "" && cp.value){
                    has = true;
                    msg = "存在未选择操作符的行";
                    break;
                }else if(set.opType && set.variable === "" && !cp.value){
                    has = true;
                    msg = "存在未选择常量的行";
                    break;
                }else if(set.opType && set.variable !== "" && !cp.value){
                    has = true;
                    msg = "存在未设置值的行";
                    break;
                }
            }
            
            filterData.push(cp);
        } else if (item.fieldType === 7) {
          cp.opType = "";
          if (set.variable == "0") { //自定义
              cp.valueType = "30";
              let s_val = set.startTime;
              let e_val = set.endTime;
              if(set.curStart){
                  s_val = this.sysdate;
              }
              if(set.curEnd){
                  e_val = this.sysdate;
              }

              if (s_val && e_val && s_val > e_val && s_val != this.sysdate && e_val != this.sysdate) {
                  has = true;
                  msg = "存在开始时间大于结束时间的行";
                  break;
              }

              if(s_val || e_val){
                  cp.value = [s_val, e_val].join(",");
                  if(!s_val){
                      cp.valueText = '<='+e_val;
                  }else if(!e_val){
                      cp.valueText = '>='+s_val;
                  }else{
                      cp.valueText = [s_val, e_val].join(",");
                  }
              }else{
                  has = true;
                  msg = "存在未设置值的行";
                  break;
              }
              filterData.push(cp);

              //用户端创建时间字段  只能选取一年内数据 v4.8 py
              if(cp.nameVariable === "createTime"){
                  if(s_val == e_val == this.sysdate){
                    console.log(1);
                  }else{
                      let ss = s_val == this.sysdate ? new Date() : s_val ,
                          ee = e_val == this.sysdate ? new Date() : e_val ,
                          ssd = new Date(ss).addYear(1);
                      if(new Date(ee).getTime() > new Date(ssd).getTime()){
                          has = true;
                          msg = "创建时间只能选择一年以内";
                          break;
                      }
                  }
              }

          } else if (set.variable !== "") {
              cp.valueType = "3";
              cp.value = set.variable;
              cp.valueText = this.getVaiableText(item);
              filterData.push(cp);
          }
        }
      }
      if(has){
        this.$message(msg);
        return ;
      }
      var re = {
        filterData:filterData
      }
      //对比数据
      if(this.showCompare){
        re.filterDefault = this.filterDefault;
        re.compDefault = this.compDefault;
        //勾选的对比字段
        let compData = this.cList.filter(item => item.isChecked === true);
        re.compData = compData;
      }
      this.visiable = false;
      this.$emit("filterSave",re);
    },
    closeFn() {
      this.visiable = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.theme-filter {
  & /deep/ .el-dialog__header {
    text-align: left;
  }
  & /deep/ .el-dialog__body{
    padding: 0 20px;
  }
  & /deep/ .el-input{
    font-size: 12px;
  }
  .tab-wrap{
    margin-bottom: 5px;
  }
  .check-wrap{
    margin-top: 10px;
  }
  .edit-col-wrap{
    display: flex;
    justify-content:flex-start;
    align-items: center;
    & .mod{
      margin-left:5px;
      margin-right: 5px;
    }
    .date7-wrap{
      display: flex;
      align-items: center;
      margin-left: 5px;
      flex:1;
      & /deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner{
        width:180px !important;
      }
    }
    /deep/ .en-input-range,.el-select{
      width:100% !important;
    }
  }
}
</style>
