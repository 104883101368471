var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "time-resource",
      style: _vm.bindStyle,
      on: { click: _vm.openList },
    },
    [
      _c("el-popover", { attrs: { placement: "bottom", trigger: "hover" } }, [
        _c("div", { staticClass: "cardpopo" }, [
          _c("div", {
            staticClass: "col-txts col-tits",
            staticStyle: { position: "relative" },
          }),
          _c("div", { staticClass: "col-supernatant" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.item.name)),
            ]),
            _c("div", { staticClass: "col-state item" }, [
              _c("span", { staticClass: "iconfont icon" }, [_vm._v("")]),
              _c("span", { staticClass: "time-date" }, [
                _vm._v(_vm._s(_vm.item.P6)),
              ]),
            ]),
            _c("div", { staticClass: "col-state item" }, [
              _c("span", { staticClass: "iconfont icon" }, [_vm._v("")]),
              _c("span", { staticClass: "time-date" }, [
                _vm._v(_vm._s(_vm.item.refResource[0].name)),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "users item" },
              [
                _c("span", { staticClass: "iconfont icon" }, [_vm._v("")]),
                _c(
                  "el-popover",
                  {
                    staticClass: "poopover",
                    attrs: {
                      placement: "bottom",
                      width: "100%",
                      trigger: "hover",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tag-list" },
                      _vm._l(_vm.item.m6516, function (it) {
                        return _c(
                          "div",
                          {
                            key: it.id,
                            staticClass: "tag-item-box",
                            attrs: { size: "small", type: "info" },
                          },
                          [
                            _c("div", { staticClass: "sculpture-style-two" }, [
                              _c("span", [_vm._v(_vm._s(it.name[0]))]),
                            ]),
                            _c("div", [_vm._v(_vm._s(it.name))]),
                          ]
                        )
                      }),
                      0
                    ),
                    _c(
                      "div",
                      { attrs: { slot: "reference" }, slot: "reference" },
                      [
                        _vm.item.m6516 && _vm.item.m6516.length > 3
                          ? _c(
                              "div",
                              { staticClass: "sculpture-list" },
                              [
                                _vm._l(
                                  _vm.item.m6516.slice(0, 3),
                                  function (item2, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "sculpture-style",
                                      },
                                      [
                                        index < 3
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s([...item2.name][0] || "")
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    )
                                  }
                                ),
                                _vm.item?.m6516?.length >= 3
                                  ? _c(
                                      "div",
                                      { staticClass: "sculpture-style-other" },
                                      [
                                        _vm._v(
                                          " +" +
                                            _vm._s(_vm.item.m6516.length - 3) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _c(
                              "div",
                              { staticClass: "en-result-tags" },
                              _vm._l(_vm.item.m6516, function (item2, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "en-select-tag" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "en-select__tags-thum en-select__tags-thum-text",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(item2.name.substr(0, 1)) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "el-select__tags-text" },
                                      [_vm._v(_vm._s(item2.name))]
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "left",
            attrs: { slot: "reference" },
            slot: "reference",
          },
          [
            _c("div", { staticClass: "txts-wrap" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.item.refType === 23 && _vm.item.readFlag === "000",
                      expression: "item.refType===23&&item.readFlag==='000'",
                    },
                  ],
                  staticClass: "dot",
                },
                [_c("div", { staticClass: "in" })]
              ),
              _c(
                "div",
                {
                  staticStyle: { "padding-right": "5px" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _vm.item.createId.length &&
                  _vm.item.createId[0].id === _vm.userInfo.userId
                    ? _c("el-checkbox", {
                        on: {
                          change: function ($event) {
                            return _vm.finish(_vm.item)
                          },
                        },
                        model: {
                          value: _vm.item.isChecked,
                          callback: function ($$v) {
                            _vm.$set(_vm.item, "isChecked", $$v)
                          },
                          expression: "item.isChecked",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("div", {
                staticClass: "txts-event",
                class: { finish: _vm.item.isChecked },
                domProps: { innerHTML: _vm._s(_vm.item.titleTxts) },
                on: {
                  click: function ($event) {
                    return _vm.readMsg(_vm.item)
                  },
                },
              }),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }