var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      attrs: { title: "选择共享人", visible: _vm.show, width: "900px" },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c("en-tabs", {
        attrs: { list: _vm.tabList, type: "white" },
        on: { change: _vm.changeTabs },
        model: {
          value: _vm.activeTab,
          callback: function ($$v) {
            _vm.activeTab = $$v
          },
          expression: "activeTab",
        },
      }),
      _c(
        "div",
        { staticClass: "user-list" },
        _vm._l(_vm.personList, function (item) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "mods",
              on: {
                click: function ($event) {
                  return _vm.addFrequent(item)
                },
              },
            },
            [
              _c("en-user-logo", {
                attrs: { "user-name": item.name, size: "32px" },
              }),
              _c("span", { staticClass: "names" }, [_vm._v(_vm._s(item.name))]),
              _vm.activeTab === "1"
                ? _c("en-icon", {
                    staticClass: "del-icon",
                    attrs: { name: "iconshanchu-liebiao", size: "small" },
                    nativeOn: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.deleteFrequent(item)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "en-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("nform")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }