<!--
 * @Author: zhulin
 * @Date: 2020-08-22 16:33:02
 * @LastEditors: wuqi
 * @LastEditTime: 2021-08-25 18:13:24
 * @Description: 主题-头部通用右侧
 * @FilePath: \user\src\views\themeSet\components\btnComm.vue
-->
<template>
  <div>
    <div class="btn-list" v-if="btnList && btnList.length">
      <div class="mods">
        <span class="btn-mod" @click="filter"><en-icon name="iconshaixuan" size="small" color="#8a77af"></en-icon><span class="txts">筛选</span></span>
      </div>
      <div class="mods" v-for="item in btnNewList" :key="item.btnKey">
        <span class="btn-mod" v-if="item.btnKey === 'compare'" @click="compareChange(item)"
          ><en-icon name="iconduibi" size="small" color="#bfa596"></en-icon><span class="txts">{{ item.btnName }}</span></span
        >
        <span class="btn-mod" v-if="item.btnKey === 'export'" @click="exportChange(item)"
          ><en-icon name="icondaochu" size="small" color="#62a792"></en-icon><span class="txts">{{ item.btnName }}</span></span
        >
        <span class="btn-mod" v-if="item.btnKey === 'share'" @click="shareChange(item)"
          ><en-icon name="icongongxiang" size="small" color="#ebb763"></en-icon><span class="txts">{{ item.btnName }}</span></span
        >
        <el-dropdown class="add-btn" v-if="item.btnKey === 'add'">
          <span class="el-dropdown-link">
            <el-button type="primary" size="small" icon="el-icon-plus">添加</el-button>
          </span>
          <el-dropdown-menu slot="dropdown" v-if="item.mdTemplateList && item.mdTemplateList.length"
            ><el-dropdown-item v-for="item2 in item.mdTemplateList" :key="item2.id" @click.native="addChange(item2)">{{ item2.name }}</el-dropdown-item></el-dropdown-menu
          >
        </el-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
import { newEntranceRoute, newTabAddBusinessData } from "@/components/businessDetail/helper";

export default {
  name: "btnComm",
  props: {
    btnList: {
      type: Array,
      default: () => []
    },
    templateId: {
      type: String
    }
  },
  data() {
    return {
      shareUser: []
    };
  },
  computed: {
    btnNewList() {
      const arr = this.btnList;
      const addItem = arr.find((a) => a.btnKey === "add");
      if (addItem) {
         arr.splice(
          arr.findIndex((addItem) => addItem.btnKey === "add"),
          1
        );
        arr.push(addItem);
      }
      return arr;
    }
  },
  methods: {
    handleCommand(item) {
      console.log(item);
      newTabAddBusinessData({ templateId: item.id });
      window.addEventListener("storage", this.businessDetail_businessData);
    },
    businessDetail_businessData(event) {
      if (event && event.key === "businessDetail_businessData") {
        console.log("业务建模新增回调");
        // 刷新数据
        this.$emit("refreshList");
        // 新增业务建模 刷新页面 并移除监听
        window.removeEventListener("storage", this.businessDetail_businessData);
      }
    },
    /**
     * @description: 共享跳转
     */
    shareChange() {
      this.$router.push({
        path: "/themeSet/shareList",
        query: {
          templateId: this.templateId
        }
      });
    },
    /**
     * @description: 导出
     */
    exportChange(item) {
      this.$emit("exportChange", item);
    },
    /**
     * @description: 对比
     */
    compareChange(item) {
      this.$emit("compareChange", item);
    },
    /**
     * @description: 添加
     */
    addChange(item) {
      this.$router.push(
        newEntranceRoute({ templateId: item.id, businessType: item.type })
      );
    },
    /**
     * @description: 点击筛选按钮事件
     * @param {*}
     * @return {*}
     */
    filter() {
      this.$emit("filterChange");
    }
  }
};
</script>
<style lang="scss" scoped>
.btn-list {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  .mods {
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    color: #aeaeae;
    font-size: 12px;
    margin-right: 12px;
    .en-icon {
      margin-right: 5px;
      display: inline-block;
      vertical-align: middle;
    }
    .txts {
      display: inline-block;
      vertical-align: middle;
    }
  }
}
.add-btn {
  display: inline-block;
  vertical-align: middle;
}
</style>
