<!--
 * @Author: zoujp
 * @Date: 2020-11-26 15:15:23
 * @LastEditTime: 2021-01-08 18:04:31
 * @LastEditors: Please set LastEditors
 * @Description: 对比弹窗
 * @FilePath: \user\src\views\themeSet\components\alertCompare.vue
-->
<template>
  <en-dialog
    title="选择对比人"
    v-if="visiable"
    :visible.sync="visiable"
    width="900px"
    :close-on-click-modal="false"
    @close="closeFn"

  >
    <en-tabs :list="tabList" type="white" v-model="activeTab" @change="changeTabs"> </en-tabs>
    <div class="user-list">
      <div class="mods" v-for="item in personList" :key="item.id">
        <el-checkbox v-model="item.checked" style="margin-right:10px;"></el-checkbox>
        <en-user-logo :user-name="item.name" size="32px"></en-user-logo>
        <span class="names">{{ item.name }}</span>
        <en-icon name="iconshanchu-liebiao" size="small" class="del-icon" v-if="activeTab === '1' && item.id !== 'mine'" @click.native.stop="deleteFrequent(item)"></en-icon>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <en-button v-show="activeTab==='1'" type="primary" @click="saveCompare">确 定</en-button>
      <en-button v-show="activeTab==='2'" type="white" @click="addCompare">添加常用</en-button>
    </span>
  </en-dialog>
</template>
<script>
import { themeCommonService } from "@/api";

export default {
  name: "alertCompare",
  props: {
    templateId: {
      type: String
    },
    btnData: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: "1"
    },
    initIds: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      visiable: false,
      shareUsers: [],
      activeTab: "1",
      frequentUser: [],
      tabList: [
        {
          id: "1",
          name: "常用"
        },
        {
          id: "2",
          name: "更多"
        }
      ]
    };
  },
  mounted() {

  },
  watch: {
    /**
     * @description: 监听弹窗
     */
    visiable(val) {
      if (val) {
        this.queryShareUser();
      }
    }
  },
  computed: {
    personList() {
      const res = this.activeTab === "1" ? this.frequentUser : this.shareUsers;
      return res;
    }
  },
  methods: {
    /**
     * @description: 添加到常用
     * @param {*}
     * @return {*}
     */
    async addCompare() {
      const that = this;
      const ids = [];
      for (let i = 0; i < this.personList.length; i++) {
        const m = this.personList[i];
        if (m.checked) {
          ids.push(m.id);
        }
      }
      if (!ids.length) {
        this.$message("请选择人员");
      } else {
        const pa = {
          refId: ids.join(","),
          refType: 1,
          funcType: 2,
          uniqueId: this.templateId
        };
        await themeCommonService.addFrequent(pa);
        this.queryFrequent(() => {
          that.activeTab = "1";
          that.frequentUser.forEach((b) => {
            if (ids.join(",").indexOf(b.id) > -1) {
              b.checked = true;
            }
          });
        });
      }
    },
    /**
     * @description: 保存对比
     * @param {*}
     * @return {*}
     */
    saveCompare() {
      const ids = []; const names = []; const logUrls = [];
      for (let i = 0; i < this.personList.length; i++) {
        const m = this.personList[i];
        if (m.checked) {
          ids.push(m.id);
          names.push(m.name);
          logUrls.push(m.logUrl);
        }
      }
      if (!ids.length) {
        this.$message("请选择人员");
      } else if (ids.length > 3) {
        this.$message("最多同时对比3个人员");
      } else if (this.type === "1") {
          const dr = typeof this.btnData.dataRange === "string" ? JSON.parse(this.btnData.dataRange) : this.btnData.dataRange;
          this.$router.push({
            path: "/themeSet/compare",
            query: {
              templateId: this.templateId,
              id: ids.join(","),
              name: names.join(","),
              logUrl: logUrls.join(","),
              timeField: dr[0].field
            }
          });
        } else {
          this.$emit("submitCompare", ids);
        }
    },
    /**
     * @description: 查询分享用户
     */
    async queryShareUser() {
      const rsp = await themeCommonService.queryShareUser({ templateId: this.templateId });
      const arr = rsp || [];
      this.shareUsers = arr;
      this.queryFrequent();
    },
    /**
     * @description: 查询分享用户
     */
    async queryFrequent(fn) {
      const pa = { refType: 1, funcType: 2, uniqueId: this.templateId };
      const rsp = await themeCommonService.queryFrequent(pa);
      const arr = rsp || [];
      this.frequentUser = arr;
      this.frequentUser.unshift({
        id: "mine", name: "我的", logUrl: ""
      });
      if (this.initIds) {
        const ids = this.initIds.split(",");
        for (let i = 0; i < ids.length; i++) {
          for (let j = 0; j < this.frequentUser.length; j++) {
            if (ids[i] === this.frequentUser[j].id) {
              this.frequentUser[j].checked = true;
              // eslint-disable-next-line
              continue;
            }
          }
        }
      }
      fn && fn();
    },
    /**
     * @description: 关闭弹窗回调
     */
    closeFn() {
      this.visiable = false;
    },
    /**
     * @description: 切换页签
     * @param {*}
     * @return {*}
     */
    changeTabs() {
      console.log(this.activeTab);
    },
    /**
     * @description: 删除常用人
     */
    async deleteFrequent(item) {
      const pa = {
        refId: item.id,
        refType: 1,
        funcType: 2,
        uniqueId: this.templateId
      };
      await themeCommonService.deleteFrequent(pa);
      this.$message("删除成功");
      this.queryFrequent();
    }
  }
};
</script>
<style lang="scss" scoped>
.btn-list {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  .mods {
    margin-right: 8px;
    cursor: pointer;
  }
}
.add-btn {
  display: inline-block;
  vertical-align: middle;
}
.en-tabs {
  padding: 0;
}
.en-tabs /deep/ .en-tabs-item {
  width: 106px;
}
.en-tabs /deep/.active_bar {
  width: 120px !important;
}
.user-list {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #eaebef;
  padding: 10px 0;
  border-radius: 5px;
  border-top: 0;
  min-height: 400px;
  align-content: flex-start;
  .mods {
    width: 50%;
    cursor: pointer;
    padding: 0 20px;
    border-radius: 5px;
    .names {
      margin-left: 10px;
      font-size: 12px;
    }
    .del-icon {
      float: right;
      margin-top: 7px;
    }
    &:hover {
      background: #f5f8fd;
    }
  }
}
</style>
