<!--
 * @Author: zoujp
 * @Date: 2020-11-28 09:38:22
 * @LastEditTime: 2024-03-14 10:44:47
 * @LastEditors: Please set LastEditors
 * @Description: 日历视图
 * @FilePath: \user\src\views\themeSet\viewCalendar\index.vue
-->

<template>
  <div class="theme-set" v-en-loading="enloading">
    <div class="topbar" v-if="!refData">

      <div class="pull-left">
        <en-icon  class="back-icon" name="iconfanhuishangcengji" size="16px"
              color="#A9B5C6" @click.native="handleBackIconClick"></en-icon>
        <div class="header-icon">
            <en-icon  name="iconmoban-yewujianmo1" size="16"
                  color="#FCFCFC"></en-icon>
        </div>
          <span class="title-text">{{ templateName || '' }}</span>
          <themeViewComm :templateId="templateId" :viewId="viewId" :templateName="templateName"></themeViewComm>
      </div>
      <div class="pull-right">
        <btnComm :btnList="batchBtnList" :templateId="templateId" @refreshList="getTimeList" @filterChange="filterChange" @compareChange="compareChange"></btnComm>
      </div>
    </div>
    <div class="centbar">
      <div class="changeView">
        <div class="en-date-picker-header">
        <!-- <div class="en-date-picker-header" :style="{width:!refData?'400px':''}"> -->
          <en-icon name="fanhui" @click.native="changeMonth(-1)"> </en-icon>
          <el-date-picker
            v-model="changeDate"
            :type="activeView==='month'?'month':activeView==='week'?'week':'date'"
            placeholder="选择日期"
            firstDayOfWeek="1"
            :format="activeView==='week'?'yyyy 第 WW 周' : ''"
            :clearable="false"
            :editable="false"
            style="width:80px;"
            @change="changeMonth(0)"
          > </el-date-picker>
          <en-icon name="fanhui" @click.native="changeMonth(1)"> </en-icon>
        </div>
        <!-- <div class="tab-view">
          <en-tabs :list="viewList" type="white" v-model="activeView" @change="changeViews"> </en-tabs>
        </div> -->
        <!-- 图表右侧按钮 -->
        <div class="change-btn-box">
          <div class="todat-btn" @click="today">今日</div>
          <el-select
            class="select-time"
            @change="changeViews"
            v-model="activeView"
            placeholder="请选择"
          >
            <el-option
              v-for="item in viewList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="view-calendar">
        <viewsCalender
        v-if="activeView === 'month'"
          @refreshList="getTimeList"
          :data="calendarData"
          :changeDate="changeDate"
          :startD="startD"
          :endD="endD"
          :templateId="templateId"
          @reload="reload"
        ></viewsCalender>
        <viewsTimeCalender
          v-if="activeView === 'week' || activeView === 'day'"
          :data="calendarData"
          :view="activeView"
          :changeDate="changeDate"
          :startD="startD"
          :endD="endD"
          :templateId="templateId"
          :timeField="timeField"
          @reload="reload"
        ></viewsTimeCalender>
      </div>
    </div>
    <filterComm ref="popFilter" :fieldList='colList' :searchParams='conditions' :setBaseData="setBaseData"  @filterSave="filterSave" @showBaseData="showBaseData"></filterComm>
    <alertCompare ref="popCompare" :templateId="templateId" :btnData="btnData"></alertCompare>
    <baseDataComm ref="popBaseData" :filterRowData="filterRowData" @saveBaseData="saveBaseData"></baseDataComm>
    <businessSearchDialog
    @confirmSearch="filterSave"
    :search-field-list="colList"
    ref="searchDialog"
  >
  </businessSearchDialog>
  </div>
</template>
<script>

import { mapGetters } from "vuex";
import dayjs from "dayjs";
import { cloneDeep } from "lodash";
import { calendarListService } from "@/api";

import btnComm from "../components/btnComm";
import shareComm from "../components/shareComm";
import viewComm from "../components/viewComm";
import themeViewComm from "../components/themeViewComm";
import filterComm from "../components/filterComm";
import baseDataComm from "../components/selectBaseData";
import alertCompare from "../components/alertCompare";
import viewsCalender from "../components/viewsCalender";
import viewsTimeCalender from "../components/viewsTimeCalender";
import * as utils from "../components/model/util/index";

export default {
  name: "viewCalendar",
  components: {
    btnComm,
    // shareComm,
    // viewComm,
    themeViewComm,
    filterComm,
    baseDataComm,
    alertCompare,
    viewsCalender,
    viewsTimeCalender
  },
  data() {
    return {
      enloading: false,
      viewId: this.$route.query.viewId || this.$attrs.menuData?.refData?.viewId,
      templateName: this.$route.query.templateName || this.$attrs.menuData?.refData?.name,
      templateId: this.$route.query.templateId || this.$attrs.menuData?.refData?.id,
      batchBtnList: [],
      singleBtnList: [],
      supernatantFields: [],
      titleFields: [],
      viewList: [],
      activeView: "month",
      calendarData: {},
      reQueryCalendar: [],
      colList: [],
      conditions: [],
      setBaseData: {},
      btnData: {},
      filterRowData: {},
      shareUserId: "",
      timeField: "createTime",
      changeDate: dayjs(new Date()).format("YYYY-MM-DD"),
      startD: "",
      endD: "",
      iconMap: {
        1: "shijian-xuanxiang",
        2: "dizhi-kaoqin",
        3: "renyuan-wodehangcheng",
        4: "",
        5: "",
        6: "",
        7: "",
        8: ""
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getConfig();
    });
  },
  computed: {
    ...mapGetters(["userInfo"]),
    refData() {
      return this.$attrs.menuData?.refData;
    }
  },
  watch: {
    refData: {
      handler(v) {
        if (v) {
          this.viewList = [{ id: "day", name: "今" }];
          this.activeView = "day";
        } else {
          this.viewList = [{ id: "month", name: "月" }, { id: "week", name: "周" }, { id: "day", name: "日" }];
           this.activeView = "month";
        }
      },
      immediate: true
    }
  },
  methods: {
       // 返回按钮点击
    handleBackIconClick() {
      this.$router.back();
    },
    today() {
      this.changeDate = dayjs(new Date()).format("YYYY-MM-DD");
      this.startD = dayjs(this.changeDate).startOf(this.activeView).format("YYYY-MM-DD");
      this.endD = dayjs(this.changeDate).endOf(this.activeView).format("YYYY-MM-DD");
      this.getTimeList();
    },
    reload() {
      this.getTimeList();
    },
    /**
     * @description 月份切换
     * @param type 1为下个月，-1为上个月
     */
    changeMonth(type) {
      const { activeView } = this;
      this.changeDate = dayjs(this.changeDate)
        .add(type, this.activeView)
        .format("YYYY-MM-DD");
      if (activeView === "month" || activeView === "week") {
        this.startD = dayjs(this.changeDate).startOf(activeView).format("YYYY-MM-DD");
        this.endD = dayjs(this.changeDate).endOf(activeView).format("YYYY-MM-DD");
      } else {
        this.startD = this.changeDate;
        this.endD = dayjs(this.changeDate).add(1, "day").format("YYYY-MM-DD");
      }
      this.getTimeList();
    },
    /**
     * @description: 查询管理端配置
     * @param {*}
     * @return {*}
     */
    async getConfig() {
      console.log("11111");
      const rsp = await calendarListService.queryCalendarConfig({ templateId: this.templateId });
      this.titleFields = rsp.tileFields;
      this.supernatantFields = rsp.supernatantFields;
      this.singleBtnList = rsp.singleBtnList;
      this.batchBtnList = rsp.batchBtnList;
      this.timeField = rsp.timeField;
      this.colList = cloneDeep(this.titleFields);
      this.changeMonth(0);
    },
    /**
     * @description: 切换视图月日周
     * @param {*}
     * @return {*}
     */
    changeViews() {
      this.changeMonth(0);
    },
    /**
     * @description: 过滤弹窗回调
     * @param {*} cons
     * @return {*}
     */
    filterSave(re) {
      this.conditions = JSON.parse(re.searchParams || JSON.stringify([]));
      this.getTimeList();
    },
    /**
     * @description: 选择基础数据
     * @param {*}
     * @return {*}
     */
    showBaseData(row) {
      this.$refs.popBaseData.visiable = true;
      this.filterRowData = row;
    },
    /**
     * @description:基础数据内容保存
     * @param {*}
     * @return {*}
     */
    saveBaseData(par) {
      this.setBaseData = par;
    },
    /**
     * @description: 分享回调
     * @param {*} share
     * @return {*}
     */
    saveCallback(share) {
      this.shareUserId = share.shareUserId;
      this.pageNo = 1;
      this.getTimeList();
    },
    /**
     * @description: 过滤按钮回调
     * @param {*}
     * @return {*}
     */
    filterChange() {
      // this.$refs.popFilter.visiable = true;
      this.$refs.searchDialog.open();
    },
    /**
     * @description: 对比按钮回调
     * @param {*}
     * @return {*}
     */
    compareChange(btnData) {
      this.$refs.popCompare.visiable = true;
      this.btnData = btnData;
    },
    /**
     * @description: 获取数据列表
     * @param {*}
     * @return {*}
     */
    async getTimeList() {
      const params = {
        templateId: this.templateId,
        startDate: this.startD,
        endDate: this.endD,
        conditions: JSON.stringify(this.conditions),
        shareUserId: this.shareUserId,
        isPreview: 1,
        pageNo: 1,
        pageSize: 9999
      };
      const rsp = await calendarListService.queryCalendarList(params);
      this.calendarData = rsp;
      if (!this.calendarData) {
        this.$message({
          message: "此时间段暂无数据"
        });
      }
      if (this.calendarData) {
        Object.keys(this.calendarData).forEach((key) => {
          this.calendarData[key].forEach((item) => {
            // 设置是否选中
            this.$set(item, "isChecked", item.finishType.toString() === "1");
            this.getItemRow(item);
          });
          if (this.activeView !== "month") {
            this.calendarData[key] = this.calendarData[key].sort((a, b) => new Date(a.createTime).getTime() - new Date(b.createTime).getTime());
          }
        });
      }
      console.log("calendarData:", this.calendarData);
    },
    getItemRow(data) {
      const titleTxts = this.getTxts(data);
      const supernatantTxts = this.getTxts(data, "pop");
      // 生成标题拼接数据
      data.titleTxts = titleTxts;
      // 生成浮窗拼接数据
      data.supernatantTxts = supernatantTxts;
    },
    getTxts(data, type) {
      const fieldData = type === "pop" ? this.supernatantFields : this.titleFields;
      const txt = [];
      fieldData.forEach((a) => {
        let value = data[a.field];
        if (!value) {
          return true;
        }
        const colType = a.fieldType;
        let str = "";
        if (colType === 5 || colType === 15 || colType === 33) {
          if (value.length) {
            str = value.map((ele) => ele.name).join(",");
          }
        } else if (colType === 3 || colType === 4) {
          value = Number(value);
          if (a.numberFormat === 2) { // 百分比
            value *= 100;
          }
          if (a.permillage === "0") { // 千分
            value = utils.fmoney(value, Number(a.reserverDecimal));
          } else { // 正常数值
            value = value.toFixed(Number(a.reserverDecimal));
          }
          if (a.numberFormat === 2) { // 百分比
            value += "%";
          }
          str = value;
        } else if (colType === 7) {
          str = utils.getDateAfter(value, a.timeFormat);
        } else if (colType === 29) {
          if (value.provinceName) {
            str += value.provinceName;
          }
          if (value.cityName) {
            str += value.cityName;
          }
          if (value.districtName) {
            str += value.districtName;
          }
          if (value.address) {
            str += value.address;
          }
        } else {
          str = value;
        }
        let icons = "";
        if (a.icon) {
          icons = `<en-icon name="${this.iconMap[a.icon]}" color="color:${a.contentColor}" size="small"></en-icon>`;
        }
        const dt = `<span class="txts" style="color:${a.contentColor || ""}">${icons}${str}</span>`;
        if (dt) {
          txt.push(dt);
        }
        return txt;
      });
      return txt.join("");
    }
  }
};
</script>

<style lang="scss" scoped>
.theme-set {
  background: #fff;

  .topbar {
    height: 50px;
    border-bottom: 1px solid #eaebf0;
    padding: 0 10px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .back-icon{
      margin-right: 12px;
    }
    .pull-center {
      position: absolute;
      width: 200px;
      left: 50%;
      margin-left: -100px;
      line-height: 50px;
      font-size: 14px;
    }
    .pull-left {
      font-size: 12px;
      .col-names {
        line-height: 34px;
        margin-left: 8px;
        color: #585858;
      }
      .en-select {
        margin-left: 8px;
      }
    }

    .back-btn {
      display: inline-block;
      vertical-align: middle;
      color: #aeaeae;
    }
  }
  .centbar {
    height: calc(100% - 50px);
  }
}
.view-table {
  height: calc(100% - 50px);
  margin-top: -1px;
  .table-cent {
    height: calc(100% - 60px);
  }
}
.changeView {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #F5F7FA;
  .en-date-picker-header {
    display: flex;
    justify-content: flex-start;
    line-height: 44px;
    font-size: 12px;
    font-weight: 500;
    color: #1E1E1E;
    border-bottom: 1px solid #e8ecf2;
    cursor: pointer;
    .en-icon {
      width: 12px;
      height: 12px;
      margin: 16px 5px;
      &:nth-last-child(1) {
        transform: rotate(180deg);
        margin-left: 22px;
      }

    }
    .el-date-editor {
      /deep/ .el-input__inner {
        padding: 0;
        text-align: center;
        width: 100%;
        border: 0;
        background: 0;
        color: #1E1E1E;
        font-weight: 500;
        margin-left: 20px;
      }
      /deep/ .el-input__prefix {
        margin-left: -10px;
        color:#A9B5C6;
      }
    }
  }

  .tab-view{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    .en-tabs {
      background: none;
      padding: 0;
      line-height: 32px;
    }
    .en-tabs /deep/ .en-tabs-item {
      padding: 0;
      width: 120px;
      text-align: center;
      display: flex;
      align-items: center;
      height: 44px;
    }
    .en-tabs /deep/ .active_bar {
      width: 120px !important;
    }
  }
}
.view-calendar {
  height: 100%;
  overflow: hidden;
  /deep/ .en-date-picker {
    overflow: hidden;
  }
  /deep/ .en-date-picker .en-date-picker-week{
    background-color: #fff;
  }
  /deep/ .en-date-picker .en-date-picker-body {
    // overflow: hidden;

  }
  /deep/ .en-date-picker .en-date-picker-body {
    width: 100%;
    height: 100%;
    overflow: auto; /* 启用滚动效果 */
    border-bottom: 1px solid #e8ecf2;
  }
  /deep/ .en-date-picker .en-date-picker-body::-webkit-scrollbar {
    width: 0;
  background: transparent;
  }
  /deep/ .en-date-picker .en-date-picker-body {
    scrollbar-width: none;
  }
}
.pull-left {
  display: flex;
  justify-content: left;
  align-items: center;
  .en-icon {
    color: #a9b5c6;
    cursor: pointer;
    vertical-align: middle;
  }

  .col-names {
    vertical-align: middle;
    font-size: 14px;
    color: rgba(51, 51, 51, 1);
    line-height: 50px;
    margin-right: 10px;
    font-weight: bold;
  }

  .header-icon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 11px;
    background: linear-gradient(-30deg, #3E90FE, #5FA5FF);
    border-radius: 8px;
    .en-icon {
      color: #fff
    }
  }

  .title-text {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 16px;
    color: #1A1C1E;
    margin-right:11px;
  }
}
.change-btn-box {
    display: flex;
    justify-content: right;
    align-items: center;
    flex: 1;
    // position: absolute;
    display: flex;
    // z-index: 10001;
    // right: 20px;
    // top: 16px;
    align-items: center;

    .todat-btn {
      width: 48px;
      height: 26px;
      background: #ffffff;
      border: 1px solid #e8ecf2;
      border-radius: 13px;
      text-align: center;
      line-height: 27px;
      font-size: 11px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      cursor: pointer;
    }
    .todat-btn:hover{
      color: #3e90fe;
    }
    .todat-btn-active {
      color: #3e90fe;
    }
    .select-time {
      margin-left: 10px;
      margin-right: 20px;
      width: 48px;
      height: 26px;
      line-height: 26px;
      background: #ffffff;
      border: 1px solid #e8ecf2;
      border-radius: 13px;
      color: #636c78;
      padding: 5px;
      /deep/ .el-input--suffix{
        height: 26px;
        margin-top: -8px;
        padding: 0;
      }
      /deep/ .el-input__icon{
        width: 10px;
      }
      /deep/ .el-input__inner{
        height: 14px;
        min-height: 14px;
        line-height: 20px;
        padding: 0;
        width: 16px;
        display: inline-block;
        margin-left: 4px;
        margin-top: 8px;
        border: none;
      }
      /deep/ .el-input__suffix{
        height: 30px;
        // height: 20px;
      }
    }
    .select-time:hover /deep/ .el-input__inner{
      color: #3e90fe;
    }
  }

  .ml22{
    margin-left: 22px !important;
  }
</style>
