var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "share-main" },
    [
      _c(
        "div",
        { staticClass: "txts-main" },
        [
          !_vm.shareUserId
            ? _c("span", { staticClass: "txt-mine" }, [
                _vm._v("我的" + _vm._s(_vm.templateName)),
              ])
            : _c("span", [
                _c(
                  "span",
                  { staticClass: "txt-backmine", on: { click: _vm.backmine } },
                  [_vm._v("我的")]
                ),
                _c("span", { staticClass: "txt-other" }, [
                  _vm._v(
                    _vm._s(_vm.shareUserName) + "的" + _vm._s(_vm.templateName)
                  ),
                ]),
              ]),
          _vm.shareUsers && _vm.shareUsers.length
            ? _c("en-icon", {
                staticClass: "share-icon",
                attrs: { name: "iconjiantou-wodezhuti", size: "16" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.changeShare.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("alertShare", {
        attrs: {
          show: _vm.showShareAlert,
          shareUsers: _vm.shareUsers,
          templateId: _vm.templateId,
        },
        on: { toShow: _vm.toShow, refreshData: _vm.refreshData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }